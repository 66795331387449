import React, { Component } from 'react';
import history from 'utils/history';

export default class login extends Component {
  componentDidMount() {
    localStorage.clear();
    history.push('/welcome');
  }
  render() {
    return <div />;
  }
}
