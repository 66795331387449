import React from 'react';
import PropTypes from 'prop-types';
import HeaderBar from '../HeaderBar';
import { StyledDock, StyledDockBody } from './dock.style';

const Dock = ({ title, children, image, padding, ...rest }) => (
  <StyledDock {...rest}>
    <HeaderBar
      text={title}
      image={image}
      style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
    />
    <StyledDockBody padding={padding}>{children}</StyledDockBody>
  </StyledDock>
);

Dock.defaultProps = {
  padding: true
};

Dock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  image: PropTypes.string,
  padding: PropTypes.bool
};

export default Dock;
