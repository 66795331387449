import { GET_FRIENDS_SUCCESS } from './getFriends/getFriends.actions';
import { ACCEPT_FRIEND_REQUEST_SUCCESS } from '../friendRequests/acceptFriendRequest/acceptFriendRequest.actions';
import { REMOVE_FRIEND_SUCCESS } from './removeFriend/removeFriend.actions';

const initialState = {
  list: []
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_FRIENDS_SUCCESS: {
      return Object.assign({}, state, { list: payload.friends });
    }
    case ACCEPT_FRIEND_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        list: [payload.newFriend, ...state.list]
      });
    }
    case REMOVE_FRIEND_SUCCESS: {
      return Object.assign({}, state, {
        list: state.list.filter(x => x.id !== payload.friendId)
      });
    }

    default:
      return state;
  }
};
