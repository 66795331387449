const getParentHeight = (orientation, height, fluid) => {
  var parentHeight;
  if (orientation === 'horizontal') {
    parentHeight = height;
  } else if (orientation === 'vertical') {
    if (fluid) {
      parentHeight = '100%';
    } else {
      parentHeight = 'auto';
    }
  }
  return parentHeight;
};

const getParentWidth = (orientation, width, fluid) => {
  var parentWidth;
  if (orientation === 'vertical') {
    parentWidth = width;
  } else if (orientation === 'horizontal') {
    if (fluid) {
      parentWidth = '100%';
    } else {
      parentWidth = 'auto';
    }
  }
  return parentWidth;
};

export { getParentHeight, getParentWidth };
