import styled, { css } from 'styled-components';

const StyledInput = styled.input`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  height: 14px;
  padding: 9.5px 14px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top-color: rgba(34, 36, 38, 0.15);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgba(34, 36, 38, 0.15);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgba(34, 36, 38, 0.15);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgba(34, 36, 38, 0.15);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  ${({ fluid }) =>
    fluid &&
    css`
      width: 100%;
    `};
`;

export default StyledInput;
