import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Image, Button } from 'components';
import StyledEditImage, { StyledLabel } from './editImage.style';

const EditImage = ({
  name,
  image,
  onChange,
  deletable,
  imagePreviewSize,
  ...rest
}) => {
  const { t } = useTranslation('common');
  return (
    <StyledEditImage {...rest}>
      <Image
        src={image}
        style={{ marginBottom: '2em' }}
        size={imagePreviewSize}
      />
      <StyledLabel
        id="add-computer-button"
        for={name}
        style={{ lineHeight: 'initial' }}
      >
        {t('upload')}
      </StyledLabel>
      <input
        id={name}
        type="file"
        multiple="multiple"
        name="_photos"
        accept="image/*"
        style={{ visibility: 'hidden', display: 'none' }}
        onChange={e => onChangeImage(e, onChange)}
      />
      {deletable && (
        <Button
          onClick={e => {
            onDeleteImage(e, onChange);
          }}
          name={name}
          color="red"
          style={{ marginLeft: '1em' }}
        >
          {t('delete')}
        </Button>
      )}
    </StyledEditImage>
  );
  function onChangeImage(e, onChange) {
    const { name } = e.target;
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        onChange(null, {
          name: [name],
          value: { file, preview: reader.result }
        });
      };
      reader.readAsDataURL(file);
    } else {
      onChange(null, { name: [name], value: { file: '', preview: '' } });
    }
  }
  function onDeleteImage(e, onChange) {
    const { name } = e.target;
    onChange(null, {
      name: [name],
      value: { file: '', preview: '' }
    });
  }
};

EditImage.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  deletable: PropTypes.bool.isRequired,
  imagePreviewSize: PropTypes.string
};

export default EditImage;
