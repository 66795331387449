import { createReducer } from 'utils/reducer';

import {
  DELETE_CHAT,
  DELETE_CHAT_FAILED,
  DELETE_CHAT_SUCCESS
} from './deleteChat.actions';
import {
  deleteChat,
  deleteChatFailed,
  deleteChatSuccess
} from './deleteChat.reducer.helper';

const initialState = {
  data: {},
  error: null,
  isSuccessful: false,
  isError: false
};

export default createReducer(initialState, {
  [DELETE_CHAT]: deleteChat,
  [DELETE_CHAT_FAILED]: deleteChatFailed,
  [DELETE_CHAT_SUCCESS]: deleteChatSuccess
});
