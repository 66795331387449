import styled, { css } from 'styled-components';
import { Dock, Image, Icon, Button } from 'components';

export const StyledPost = styled(Dock)`
  margin-bottom: 20px;
  position: relative;
`;

export const StyledPostImage = styled(Image)`
  width: 100%;
  height: auto;
  margin-top: 20px;
`;

export const StyledPostDeleteIcon = styled(Icon)`
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 18px !important;
  cursor: pointer;

  ${({ disabled }) =>
    disabled
      ? css`
          color: gray;
          cursor: not-allowed;
        `
      : css`
          &:hover {
            color: orange;
          }
        `}
`;

export const StyledPostEditIcon = styled(Icon)`
  position: absolute;
  top: 13px;
  right: 45px;
  font-size: 18px !important;
  cursor: pointer;

  &:hover {
    color: orange;
  }
`;

export const StyledPostLikeButton = styled(Button)`
  float: right;
  margin-top: 10px;
  background-color: ${({ liked, theme }) =>
    liked ? '#891B1B' : theme.colors.natural[700]};
`;

export const StyledPostLikesLabel = styled(Icon)`
  height: 26px;
  width: 26px;
  border-radius: 9999px;
  border: none;
  font-size: 14px !important;
  padding-top: 7px;
  padding-left: 6px;
  margin-top: 10px;
  margin-left: 10px;
  background-color: #891b1b !important;
`;
