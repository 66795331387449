export const CREATE_CHAT = 'CREATE_CHAT';
export const CREATE_CHAT_SUCCESS = 'CREATE_CHAT_SUCCESS';
export const CREATE_CHAT_FAILED = 'CREATE_CHAT_FAILED';

export const createChat = (memberIds, firstMessage) => ({
  type: CREATE_CHAT,
  memberIds,
  firstMessage
});

export const createChatSuccess = chat => ({
  type: CREATE_CHAT_SUCCESS,
  payload: { chat }
});

export const createChatFailed = error => ({
  type: CREATE_CHAT_FAILED,
  payload: { error }
});
