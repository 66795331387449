import { put, takeLatest } from 'redux-saga/effects';
import { apiGet } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  GET_OWN_USER,
  getOwnUserSuccess,
  getOwnUserFailed
} from './getOwnUser.actions';

function* getOwnUser() {
  try {
    const ownUser = yield apiGet('/api/user/own');
    yield put(getOwnUserSuccess(ownUser));
  } catch (error) {
    yield put(getOwnUserFailed(error));
    toast.error(i18n.t('toast:getUserFailed'));
  }
}

export default function* () {
  yield takeLatest(GET_OWN_USER, getOwnUser);
}
