import localStorageService from './localStorage.service';
import darkTheme from 'styles/themes/dark.theme';
import brightTheme from 'styles/themes/bright.theme';

const setupTheme = () => {
  if (!localStorageService.getTheme()) localStorageService.setTheme('dark');
};

export const getTheme = () => {
  switch (localStorageService.getTheme()) {
    case 'dark':
      return darkTheme;
    case 'bright':
      return brightTheme;
    default: {
      setupTheme();
      return darkTheme;
    }
  }
};
