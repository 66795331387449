import { combineReducers } from 'redux';

import user from './user/user/user.reducer';
import ownUser from './user/ownUser/ownUser.reducer';
import friends from './friends/friends/friends.reducer';
import ownFriends from './friends/ownFriends/ownFriends.reducer';
import friendRequests from './friends/friendRequests/friendRequests.reducer';
import requestedFriends from './friends/requestedFriends/requestedFriends.reducer';
import notifications from './notifications/notifications.reducer';
import searchUser from './user/user/searchUser.reducer';
import feedback from './feedback/feedback.reducer';
import userPosts from './posts/userPosts/userPosts.reducer';
import search from './globalSearch/globalSearch.reducer';
import login from './login/login.reducer';
import register from './register/register.reducer';
import confirmEmail from './confirmEmail/confirmEmail.reducer';

import chats from './chat/combined.reducer';

export default combineReducers({
  login,
  register,
  confirmEmail,
  user,
  ownUser,
  friends,
  ownFriends,
  friendRequests,
  requestedFriends,
  notifications,
  searchUser,
  feedback,
  userPosts,
  chats,
  search,
});
