import { GET_OWN_FRIENDS_SUCCESS } from './getOwnFriends/getOwnFriends.actions';

const initialState = { list: [] };

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_OWN_FRIENDS_SUCCESS: {
      return Object.assign({}, state, { list: payload.ownFriends });
    }
    default:
      return state;
  }
};
