import React from 'react'
import PropTypes from 'prop-types'
import StyledDimmer from './Dimmer.style'

const Dimmer = ({ active, ...rest }) => (
  <StyledDimmer active={active} {...rest} />
)

Dimmer.defaultProps = { active: false }

Dimmer.propTypes = {
  active: PropTypes.bool
}

export default Dimmer
