import styled from 'styled-components';

const StyledHeaderBar = styled.div`
  height: 40px;
  width: 100%;
  padding: 0px 20px;
  line-height: 40px;
  color: ${({ theme }) => theme.foreground};
  font-size: ${({ theme }) => theme.fontSizes[20]};
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.natural[300]};
  position: relative;
  box-sizing: border-box;

  & img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    background-color: ${({ theme }) => theme.colors.natural[500]};
  }
`;

export default StyledHeaderBar;
