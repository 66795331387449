import styled from 'styled-components';

const StyledChatPreview = styled.div`
  height: 70px;
  background-color: ${({ theme }) => theme.colors.natural[400]};
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  color: white;

  & img {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    margin-left: 10px;
  }
`;

const StyledUsername = styled.div`
  margin-left: 70px;
  padding-top: 10px;
  font-size: ${({ theme }) => theme.fontSizes[20]};
`;

const StyledMessage = styled.div`
  margin-left: 70px;
  padding-top: 5px;
  font-size: ${({ theme }) => theme.fontSizes[12]};
  text-overflow: ellipsis;
  max-width: 40% !important;
  overflow: hidden;
`;

const StyledNewNewMessagesCount = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${({ newMessagesCount, theme }) =>
    newMessagesCount > 0 ? theme.colors.primary : '#7b7b7b'};
  height: 22px;
  width: 22px;
  border-radius: 50%;
  text-align: center;
`;

const StyledNewNewestMessageDate = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: ${({ theme }) => theme.fontSizes[12]};
`;

export {
  StyledChatPreview,
  StyledUsername,
  StyledMessage,
  StyledNewNewMessagesCount,
  StyledNewNewestMessageDate
};
