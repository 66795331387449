export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS';
export const GET_CHAT_MESSAGES_FAILED = 'GET_CHAT_MESSAGES_FAILED';

export const getChatMessages = chatId => ({
  type: GET_CHAT_MESSAGES,
  chatId
});

export const getChatMessagesSuccess = (messages, chatId) => ({
  type: GET_CHAT_MESSAGES_SUCCESS,
  payload: { messages, chatId }
});

export const getChatMessagesFailed = error => ({
  type: GET_CHAT_MESSAGES_FAILED,
  payload: { error }
});
