export const GET_CHATS = 'GET_CHATS';
export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS';
export const GET_CHATS_FAILED = 'GET_CHATS_FAILED';

export const getChats = () => ({ type: GET_CHATS });

export const getChatsSuccess = chats => ({
  type: GET_CHATS_SUCCESS,
  payload: { chats }
});

export const getChatsFailed = error => ({
  type: GET_CHATS_FAILED,
  payload: { error }
});
