import React from "react";
import PropTypes from "prop-types";
import { HeaderFive, HeaderFour, HeaderOne, HeaderThree, HeaderTwo } from "./header.style";

const Header = ({ h2, h3, h4, h5, noMargin, right, center, color, content, ...props }) => {
  if (h2)
    return (
      <HeaderTwo noMargin={noMargin} right={right} center={center} color={color} {...props}>
        {content && content}
      </HeaderTwo>
    );
  if (h3)
    return (
      <HeaderThree noMargin={noMargin} right={right} center={center} color={color} {...props}>
        {content && content}
      </HeaderThree>
    );
  if (h4)
    return (
      <HeaderFour noMargin={noMargin} right={right} center={center} color={color} {...props}>
        {content && content}
      </HeaderFour>
    );
  if (h5)
    return (
      <HeaderFive noMargin={noMargin} right={right} center={center} color={color} {...props}>
        {content && content}
      </HeaderFive>
    );
  return (
    <HeaderOne noMargin={noMargin} right={right} center={center} color={color} {...props}>
      {content && content}
    </HeaderOne>
  );
};

Header.propTypes = {
  h2: PropTypes.bool.isRequired,
  h3: PropTypes.bool.isRequired,
  h4: PropTypes.bool.isRequired,
  h5: PropTypes.bool.isRequired,
  noMargin: PropTypes.bool.isRequired,
  right: PropTypes.bool.isRequired,
  center: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  content: PropTypes.string
};

Header.defaultProps = {
  h2: false,
  h3: false,
  h4: false,
  h5: false,
  noMargin: false,
  right: false,
  center: false,
  color: "white"
};

export default Header;
