export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILED = 'UPDATE_USER_PROFILE_FAILED';

export const updateUserProfile = userData => ({
  type: UPDATE_USER_PROFILE,
  userData
});

export const updateUserProfileSuccess = user => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload: { user }
});

export const updateUserProfileFailed = error => ({
  type: UPDATE_USER_PROFILE_FAILED,
  payload: { error }
});
