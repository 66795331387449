export const ADD_FRIEND = "ADD_FRIEND";
export const ADD_FRIEND_SUCCESS = "ADD_FRIEND_SUCCESS";
export const ADD_FRIEND_FAILED = "ADD_FRIEND_FAILED";

export const addFriend = userId => ({
  type: ADD_FRIEND,
  userId
});

export const addFriendSuccess = addedFriend => ({
  type: ADD_FRIEND_SUCCESS,
  payload: { addedFriend }
});

export const addFriendFailed = error => ({
  type: ADD_FRIEND_FAILED,
  payload: { error }
});
