export const SEARCH_GLOBAL = 'SEARCH_GLOBAL';
export const SEARCH_GLOBAL_SUCCESS = 'SEARCH_GLOBAL_SUCCESS';
export const SEARCH_GLOBAL_FAILED = 'SEARCH_GLOBAL_FAILED';

export const searchGlobal = (searchTerm, includeUsers) => ({
  type: SEARCH_GLOBAL,
  searchTerm,
  includeUsers
});

export const searchGlobalSuccess = searchResults => ({
  type: SEARCH_GLOBAL_SUCCESS,
  payload: { searchResults }
});

export const searchGlobalFailed = error => ({
  type: SEARCH_GLOBAL_FAILED,
  payload: { error }
});
