import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import { READ_CHAT, readChatSuccess, readChatFailed } from './readChat.actions';

function* readChat({ chatId }) {
  try {
    yield apiPost(`/api/chat/read/${chatId}`);
    yield put(readChatSuccess(chatId));
  } catch (error) {
    yield put(readChatFailed(error));
    toast.error(i18n.t('toast:readChatFailed'));
  }
}

export default function* () {
  yield takeLatest(READ_CHAT, readChat);
}
