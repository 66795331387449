import React from 'react';
import PropTypes from 'prop-types';
import LandingPageSectionHeader, {
  LandingPageHeader,
} from '../shared/LandingPageSectionHeader';
import LandingWhatComesNextRow from './LandingWhatComesNextRow';

const LandingWhatComesNext = ({ clientUrl }) => (
  <div
    id="what-comes-next"
    style={{ backgroundColor: '#1E1D1C' }}
    className="p-5"
  >
    <LandingPageSectionHeader content="We developed the basics..." />
    <LandingWhatComesNextSpacing />
    <LandingWhatComesNextRow
      heading="User Profiles"
      text="
      Create your own profile and inspect profiles of other gamers.
      See what they are doing at the moment. Watch videos of your friends showing their skills.
      You can embed Youtube-Videos or just upload your own."
      imagePath={`${clientUrl}/images/Profile.png`}
      pictureFirst
    />
    <LandingWhatComesNextSpacing />

    <div className="d-none d-md-block">
      <LandingWhatComesNextRow
        heading="Chat"
        text="You can chat with your friends or any other player registered at Acety.
      Talk about games and videos posted by your friends.
      Or make an appointment for your next gaming night. For sure this is a live chat where you receive messages directly."
        imagePath={`${clientUrl}/images/Chat.png`}
      />
    </div>
    <div className="d-block d-md-none">
      <LandingWhatComesNextRow
        heading="Chat"
        text="You can chat with your friends or any other player registered at Acety.
      Talk about games and videos posted by your friends.
      Or make an appointment for your next gaming night. For sure this is a live chat where you receive messages directly."
        imagePath={`${clientUrl}/images/Chat.png`}
        pictureFirst
      />
    </div>

    <LandingWhatComesNextSpacing />
    <LandingWhatComesNextRow
      heading="Feedback"
      text="One of the most important features is the feedback form where you can request new features or report bugs.
      Please provide detailed information to us so we can put your wish into the next release.
      This site only can live with your support and the community."
      imagePath={`${clientUrl}/images/Feedback.png`}
      pictureFirst
    />
    <div style={{ marginBottom: '75px' }} />
    <LandingPageHeader content="...you decide what comes next!" />
    <div style={{ marginBottom: '50px' }} />
  </div>
);

function LandingWhatComesNextSpacing() {
  return (
    <>
      <div className="d-block d-md-none">
        <div style={{ marginBottom: '25px' }} />
      </div>
      <div className="d-md-block d-none">
        <div style={{ marginBottom: '150px' }} />
      </div>
    </>
  );
}

LandingWhatComesNext.propTypes = { clientUrl: PropTypes.string.isRequired };

export default LandingWhatComesNext;
