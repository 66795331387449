import { put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import {
  REGISTER_REQUEST,
  registerSuccess,
  registerFailed,
} from './register.actions';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';

export function* register({ email, password, username }) {
  try {
    yield apiPost('/api/account/register', {
      username,
      password,
      email,
    });
    // yield put(handleLoadUser(data.token));
    yield put(registerSuccess());
    toast.success(i18n.t('toast:registerSuccess'));
  } catch (error) {
    yield put(registerFailed(error));
    toast.error(i18n.t('toast:registerFailed'));
  }
}

export default function* () {
  yield takeLatest(REGISTER_REQUEST, register);
}
