import React from 'react';
import PropTypes from 'prop-types';
import { StyledMediaImage } from './Media.style';

const MediaImage = ({ src, ...rest }) => (
  <StyledMediaImage src={src} {...rest} />
);

MediaImage.propTypes = { src: PropTypes.string.isRequired };

export default MediaImage;
