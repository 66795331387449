import { GET_FRIEND_REQUESTS_SUCCESS } from './getFriendRequests/getFriendRequests.actions';
import { ACCEPT_FRIEND_REQUEST_SUCCESS } from './acceptFriendRequest/acceptFriendRequest.actions';
import { DECLINE_FRIEND_REQUEST_SUCCESS } from './declineFriendRquest/declineFriendRequest.actions';

const initialState = {
  list: []
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_FRIEND_REQUESTS_SUCCESS: {
      return Object.assign({}, state, { list: payload.friendRequests });
    }
    case DECLINE_FRIEND_REQUEST_SUCCESS:
    case ACCEPT_FRIEND_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        list: [...state.list.filter(({ id }) => payload.friendRequestId !== id)]
      });
    }
    default:
      return state;
  }
};
