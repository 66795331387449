export const ACCEPT_FRIEND_REQUEST = 'ACCEPT_FRIEND_REQUEST';
export const ACCEPT_FRIEND_REQUEST_SUCCESS = 'ACCEPT_FRIEND_REQUEST_SUCCESS';
export const ACCEPT_FRIEND_REQUEST_FAILED = 'ACCEPT_USER_FAILED';

export const acceptFriendRequest = friendRequestId => ({
  type: ACCEPT_FRIEND_REQUEST,
  friendRequestId
});

export const acceptFriendRequestSuccess = (friendRequestId, newFriend) => ({
  type: ACCEPT_FRIEND_REQUEST_SUCCESS,
  payload: { friendRequestId, newFriend }
});

export const acceptFriendRequestFailed = error => ({
  type: ACCEPT_FRIEND_REQUEST_FAILED,
  payload: { error }
});
