export const GET_OWN_USER = 'GET_OWN_USER';
export const GET_OWN_USER_SUCCESS = 'GET_OWN_USER_SUCCESS';
export const GET_OWN_USER_FAILED = 'GET_OWN_USER_FAILED';

export const getOwnUser = () => ({
  type: GET_OWN_USER
});

export const getOwnUserSuccess = ownUser => ({
  type: GET_OWN_USER_SUCCESS,
  payload: { ownUser }
});

export const getOwnUserFailed = error => ({
  type: GET_OWN_USER_FAILED,
  payload: { error }
});
