export const GET_REQUESTED_FRIENDS = 'GET_REQUESTED_FRIENDS';
export const GET_REQUESTED_FRIENDS_SUCCESS = 'GET_REQUESTED_FRIENDS_SUCCESS';
export const GET_REQUESTED_FRIENDS_FAILED = 'GET_REQUESTED_FRIENDS_FAILED';

export const getRequestedFriends = userId => ({
  type: GET_REQUESTED_FRIENDS,
  userId
});

export const getRequestedFriendsSuccess = requestedUsers => ({
  type: GET_REQUESTED_FRIENDS_SUCCESS,
  payload: { requestedUsers }
});

export const getRequestedFriendsFailed = error => ({
  type: GET_REQUESTED_FRIENDS_FAILED,
  payload: { error }
});
