import { put, takeLatest } from 'redux-saga/effects';
import { apiGet } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  GET_FRIENDS,
  getFriendsSuccess,
  getFriendsFailed
} from './getFriends.actions';

function* getFriends({ userId }) {
  try {
    const friends = yield apiGet(`/api/friends/${userId}`);
    yield put(getFriendsSuccess(friends));
  } catch (error) {
    yield put(getFriendsFailed(error));
    toast.error(i18n.t('toast:getFriendFailed'));
  }
}

function* getFriendsSaga() {
  yield takeLatest(GET_FRIENDS, getFriends);
}

export default getFriendsSaga;
