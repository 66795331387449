import { put, takeLatest } from 'redux-saga/effects';
import { apiPut } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  EDIT_USER_POST,
  editUserPostSuccess,
  editUserPostFailed
} from './editUserPost.actions';

function* editUserPost({ postId, text, media }) {
  try {
    const formData = new FormData();
    formData.set('postId', postId);
    formData.set('text', text);
    if (mediaUnchanged(media)) {
      formData.set('media', new File([0], 'empty'));
    } else if (media) {
      formData.set('media', media);
    }
    const updatedPost = yield apiPut('/api/post/', formData);
    yield put(editUserPostSuccess(updatedPost));
  } catch (error) {
    yield put(editUserPostFailed(error));
    toast.error(i18n.t('toast:editUserPostFailed'));
  }
}

function mediaUnchanged(media) {
  if (typeof media !== 'string') return false;
  return media.includes('https');
}

export default function* () {
  yield takeLatest(EDIT_USER_POST, editUserPost);
}
