import React from 'react';
import PropTypes from 'prop-types';
import LandingIconWithText from './LandingIconWithText';

const LandingDescription = ({ clientUrl }) => (
  <div className="row justify-content-center landing-description-margin">
    <div className="col-12 col-sm-3">
      <LandingIconWithText
        heading="Community-Driven"
        iconPath={`${clientUrl}/icons/community.svg`}
      >
        Acety is a gaming platform that is fully community-driven, meaning that
        the community decides where we, the developers, focus on
      </LandingIconWithText>
    </div>

    <div className="col-12 col-sm-3">
      <LandingIconWithText
        heading="Regular updates "
        iconPath={`${clientUrl}/icons/console.svg`}
      >
        Every second saturday we release a brand new version of Acety with more
        content for you to enjoy.
      </LandingIconWithText>
    </div>

    <div className="col-12 col-sm-3">
      <LandingIconWithText
        heading="Number One"
        iconPath={`${clientUrl}/icons/line-chart.svg`}
      >
       We want to become the number one gamingplatform in the world. With you we can achieve that. Help us with your ideas. Maybe they will be part of the next release.
      </LandingIconWithText>
    </div>
  </div>
);

LandingDescription.propTypes = { clientUrl: PropTypes.string.isRequired };

export default LandingDescription;
