import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  UNLIKE_USER_POST,
  unlikeUserPostsSuccess,
  unlikeUserPostsFailed
} from './unlikeUserPost.actions';

function* unlikeUserPost({ postId }) {
  try {
    const postLike = yield apiPost(`/api/post/like/${postId}`);
    yield put(unlikeUserPostsSuccess(postId, postLike.postLikeId));
  } catch (error) {
    yield put(unlikeUserPostsFailed(error));
    toast.error(i18n.t('toast:unlikePostFailed'));
  }
}

export default function* () {
  yield takeLatest(UNLIKE_USER_POST, unlikeUserPost);
}
