import React, { useEffect } from 'react';
import ConfirmEmail from './confirmEmail';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { confirmEmailRequest } from 'store/confirmEmail/confirmEmail.actions';

const ConfirmEmailContainer = ({ location }) => {
  const dispatch = useDispatch();

  const { search } = location;
  const queryStrings = qs.parse(search);

  const email = queryStrings['?email'];
  const token = queryStrings.token;

  useEffect(() => {
    dispatch(confirmEmailRequest(email, token));
  });

  return <ConfirmEmail />;
};

ConfirmEmailContainer.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ConfirmEmailContainer;
