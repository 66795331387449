import styled, { css } from 'styled-components';

const StyledChatChatBubble = styled.div`
  background-color: #7b7b7b;
  display: inline-block;
  padding: 15px;
  line-height: 100%;
  font-size: ${({ theme }) => theme.fontSizes[14]};
  border-radius: 20px;
  max-width: 50%;
  white-space: -moz-pre-wrap !important;
  white-space: -webkit-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;

  ${({ sendState, isOwnMessage, theme }) => {
    if (isOwnMessage) {
      switch (sendState) {
        case 'send': {
          return css`
            background-color: ${theme.colors.blue[400]};
          `;
        }
        case 'sending': {
          return css`
            background-color: ${theme.colors.blue[500]};
          `;
        }
        case 'failed': {
          return css`
            background-color: ${theme.colors.red[70]};
          `;
        }
        default:
          return css`
            background-color: ${theme.colors.blue[400]};
          `;
      }
    }
  }}
`;

export default StyledChatChatBubble;
