import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import LandingPageSectionHeader from '../shared/LandingPageSectionHeader';
import LandingContactTextBox from './LandingContactTextBox';
import api from '../../../services/api/api';
import 'react-toastify/dist/ReactToastify.min.css';

const LandingContact = ({ clientUrl }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  return (
    <div
      className="p-5"
      id="contact-part"
      style={{
        backgroundColor: '#1E1D1C'
      }}
    >
      <LandingPageSectionHeader content="Get in contact with us" />
      <div style={{ marginTop: '75px' }} />
      <LandingContactTextBox setValue={setName} value={name} label="Name" />
      <div style={{ marginTop: '20px' }} />
      <LandingContactTextBox
        setValue={setEmail}
        value={email}
        label="E-mail address"
      />
      <div style={{ marginTop: '20px' }} />
      <LandingContactTextBox
        setValue={setMessage}
        value={message}
        label="Message"
        isTextarea
      />
      <div
        style={{
          textAlign: 'center',
          cursor: 'pointer',
          width: '150px',
          margin: 'auto',
          marginTop: '60px'
        }}
        className="landing_button"
        onClick={() => sendMail()}
        role="button"
      >
        Send
      </div>
      <ToastContainer autoClose={8000} />
    </div>
  );
  function sendMail() {
    if (!name || !email || !message) {
      toast.error('Please fill out all fields.');
      return;
    }
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
      toast.error('Please give us an valid email address.');
      return;
    }
    api
      .post('/api/contact', {
        name,
        emailAddress: email,
        message
      })
      .then(() => {
        toast.success(
          'Thank you for contacting us. We will answer as fast as possible.'
        );
      })
      .catch(() => {
        toast.error('Sorry, something went wrong.');
      });
  }
};

LandingContact.propTypes = { clientUrl: PropTypes.string.isRequired };

export default LandingContact;
