import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OutsideClickDetector } from 'components';
import {
  StyledGlobalSearchbar,
  StyledGlobalSearchbarUnderline,
  StyledGlobalSearchIcon,
  StyledGlobalSearchIconToggle
} from './globalSearchbar.style';

const GlobalSearchbar = ({ onChange, children, ...rest }) => {
  const [showResults, toggleShowResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [fullSearchbarVisible, toggleFullSearchbar] = useState(false);
  const child = React.createElement(children, { searchTerm });
  return (
    <div {...rest}>
      <OutsideClickDetector handleClickOutside={() => toggleShowResults(false)}>
        <div style={{ position: 'relative' }}>
          {fullSearchbarVisible ? (
            <>
              <StyledGlobalSearchbar
                placeholder="Search"
                onChange={e => {
                  setSearchTerm(e.target.value);
                  onChange(e.target.value);
                }}
                onClick={() => toggleShowResults(true)}
                value={searchTerm}
              />
              <StyledGlobalSearchbarUnderline />
              <StyledGlobalSearchIcon
                size="30px"
                icon="times"
                onClick={() => toggleFullSearchbar(false)}
              />
            </>
          ) : (
            <StyledGlobalSearchIconToggle
              size="30px"
              icon="search"
              color="white"
              onClick={() => toggleFullSearchbar(true)}
            />
          )}
        </div>
        {showResults && fullSearchbarVisible && child}
      </OutsideClickDetector>
    </div>
  );
};

GlobalSearchbar.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.func.isRequired
};

export default GlobalSearchbar;
