import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Media } from 'components';
import ReportPostModal from './widgets/ReportPostModal';
import {
  StyledPost,
  StyledPostDeleteIcon,
  StyledPostLikeButton,
  StyledPostLikesLabel,
  StyledPostEditIcon
} from './post.style';

const Post = ({
  text,
  media,
  avatar,
  likes,
  isOwnPost,
  onDeletePost,
  onEditPost,
  onLikePost,
  onUnlikePost,
  didILikedThePost,
  isDeletingPost,
  t,
  reportText,
  reportReason,
  onChangeText,
  onSendReport,
  onChangeReason,
  reportReasonTypes,
  ...rest
}) => (
  <StyledPost {...rest} image={avatar}>
    {isOwnPost ? (
      <>
        <StyledPostDeleteIcon
          icon="trash"
          onClick={isDeletingPost ? {} : onDeletePost}
          disabled={isDeletingPost}
        />
        <StyledPostEditIcon icon="edit" onClick={onEditPost} />
      </>
    ) : (
      <ReportPostModal
        t={t}
        trigger={<StyledPostDeleteIcon icon="flag" />}
        reportText={reportText}
        reportReason={reportReason}
        onChangeText={onChangeText}
        onSendReport={onSendReport}
        onChangeReason={onChangeReason}
        reportReasonTypes={reportReasonTypes}
      />
    )}
    {text}
    {media && <Media src={media} />}
    <div style={{ clear: 'both' }} />
    <label>{likes.length}</label>
    <StyledPostLikesLabel icon="heart" />
    <StyledPostLikeButton
      liked={didILikedThePost}
      onClick={didILikedThePost ? onUnlikePost : onLikePost}
    >
      <Icon icon="heart" color={didILikedThePost ? '#fff' : '#000'} />
    </StyledPostLikeButton>
    <div style={{ clear: 'both' }} />
  </StyledPost>
);

Post.propTypes = {
  text: PropTypes.string.isRequired,
  media: PropTypes.string,
  avatar: PropTypes.string.isRequired,
  likes: PropTypes.array,
  isOwnPost: PropTypes.bool.isRequired,
  didILikedThePost: PropTypes.bool.isRequired,
  isDeletingPost: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onDeletePost: PropTypes.func.isRequired,
  onEditPost: PropTypes.func.isRequired,
  onLikePost: PropTypes.func.isRequired,
  onUnlikePost: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  reportText: PropTypes.string.isRequired,
  reportReason: PropTypes.string.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onSendReport: PropTypes.func.isRequired,
  onChangeReason: PropTypes.func.isRequired,
  reportReasonTypes: PropTypes.array.isRequired
};

export default Post;
