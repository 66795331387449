import API from './api';

export async function apiGet(path) {
  const response = await API.get(path);
  return response.data;
}
export async function apiPost(path, body) {
  const response = await API.post(path, body);
  return response.data;
}
export async function apiPut(path, body) {
  const response = await API.put(path, body);
  return response.data;
}
export async function apiPatch(path, body) {
  const response = await API.patch(path, body);
  return response.data;
}
export async function apiDelete(path, body) {
  const response = await API.delete(path, body);
  return response.data;
}
