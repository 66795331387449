import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

const ScrollContent = ({ children, ...rest }) => (
  <Scrollbars {...rest}>{children}</Scrollbars>
);

ScrollContent.propTypes = { children: PropTypes.node.isRequired };

export default ScrollContent;
