import { createReducer } from 'utils/reducer';
import {
  GET_CHAT_MESSAGES,
  GET_CHAT_MESSAGES_FAILED,
  GET_CHAT_MESSAGES_SUCCESS
} from './getChatMessages.actions';
import {
  getChatMessages,
  getChatMessagesFailed,
  getChatMessagesSuccess
} from './getChatMessages.reducer.handler';

const initialState = {
  data: [],
  error: null,
  isSuccessful: false,
  isError: false
};

export default createReducer(initialState, {
  [GET_CHAT_MESSAGES]: getChatMessages,
  [GET_CHAT_MESSAGES_FAILED]: getChatMessagesFailed,
  [GET_CHAT_MESSAGES_SUCCESS]: getChatMessagesSuccess
});
