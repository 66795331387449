import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EditUserPost } from 'components';
import Post from './Post';

const PostContainer = ({
  id,
  text,
  media,
  avatar,
  likes,
  ownUserId,
  deleteUserPost,
  isOwnPost,
  likeUserPost,
  unlikeUserPost,
  editUserPost,
  isDeletingPost,
  reportUserPost,
  ...rest
}) => {
  const { t } = useTranslation('post');
  const [reportText, setReportText] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const didILikedThePost = likes.some(x => x.user.id === ownUserId);
  const reportReasonTypes = [
    { key: 0, text: t('types.spam') },
    { key: 1, text: t('types.sexual') },
    { key: 2, text: t('types.violent') },
    { key: 3, text: t('types.hateful') },
    { key: 4, text: t('types.harmful') }
  ];
  const [reportReason, setReportReason] = useState(reportReasonTypes[0]);
  return isEditMode ? (
    <EditUserPost
      baseText={text}
      baseImage={media}
      onCancelEdit={onCancelEditPost}
      onSaveEdit={onSaveEditPost}
    />
  ) : (
    <>
      <Post
        text={text}
        media={media}
        avatar={avatar}
        likes={likes}
        isOwnPost={isOwnPost}
        didILikedThePost={didILikedThePost}
        isDeletingPost={isDeletingPost}
        onDeletePost={onDeletePost}
        onLikePost={onLikePost}
        onUnlikePost={onUnlikePost}
        onEditPost={onEditPost}
        t={t}
        reportText={reportText}
        reportReason={reportReason}
        onChangeText={onChangeText}
        onSendReport={onSendReport}
        onChangeReason={onChangeReason}
        reportReasonTypes={reportReasonTypes}
        {...rest}
      />
    </>
  );
  function onDeletePost() {
    deleteUserPost(id);
  }
  function onLikePost() {
    likeUserPost(id);
  }
  function onUnlikePost() {
    unlikeUserPost(id);
  }
  function onEditPost() {
    setIsEditMode(true);
  }
  function onCancelEditPost() {
    setIsEditMode(false);
  }
  function onSaveEditPost(text, image) {
    setIsEditMode(false);
    if (image.preview === media) {
      editUserPost(id, text, media);
      return;
    }
    editUserPost(id, text, image.file);
  }
  function onChangeText(e) {
    setReportText(e.target.value);
  }
  function onChangeReason(e) {
    setReportReason(e);
  }
  function onSendReport() {
    if (reportReason && reportText) reportUserPost(id, reportText, reportReason.key);
  }
};

PostContainer.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  media: PropTypes.string,
  avatar: PropTypes.string.isRequired,
  likes: PropTypes.array,
  ownUserId: PropTypes.string.isRequired,
  isOwnPost: PropTypes.bool.isRequired,
  isDeletingPost: PropTypes.bool.isRequired,
  deleteUserPost: PropTypes.func.isRequired,
  likeUserPost: PropTypes.func.isRequired,
  unlikeUserPost: PropTypes.func.isRequired,
  editUserPost: PropTypes.func.isRequired,
  reportUserPost: PropTypes.func.isRequired
};

export default PostContainer;
