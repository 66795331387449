export const DECLINE_FRIEND_REQUEST = 'DECLINE_FRIEND_REQUEST';
export const DECLINE_FRIEND_REQUEST_SUCCESS = 'DECLINE_FRIEND_REQUEST_SUCCESS';
export const DECLINE_FRIEND_REQUEST_FAILED = 'DECLINE_USER_FAILED';

export const declineFriendRequest = friendRequestId => ({
  type: DECLINE_FRIEND_REQUEST,
  friendRequestId
});

export const declineFriendRequestSuccess = friendRequestId => ({
  type: DECLINE_FRIEND_REQUEST_SUCCESS,
  payload: { friendRequestId }
});

export const declineFriendRequestFailed = error => ({
  type: DECLINE_FRIEND_REQUEST_FAILED,
  payload: { error }
});
