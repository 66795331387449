export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL_REQUEST';
export const CONFIRM_EMAIL_REQUEST_SUCCESS = 'CONFIRM_EMAIL_REQUEST_SUCCESS';
export const CONFIRM_EMAIL_REQUEST_FAILED = 'CONFIRM_EMAIL_REQUEST_FAILED';

export const confirmEmailRequest = (email, token) => ({
  type: CONFIRM_EMAIL_REQUEST,
  email,
  token,
});

export const confirmEmailSuccess = () => ({
  type: CONFIRM_EMAIL_REQUEST_SUCCESS,
});

export const confirmEmailFailed = (error) => ({
  type: CONFIRM_EMAIL_REQUEST_FAILED,
  payload: { error },
});
