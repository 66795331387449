export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED';

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS
});

export const getNotificationsSuccess = notifications => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: { notifications }
});

export const getNotificationsFailed = error => ({
  type: GET_NOTIFICATIONS_FAILED,
  payload: { error }
});
