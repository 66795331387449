import React from 'react';
import PropTypes from 'prop-types';
import { StyledModalBody } from './modal.style';

const ModalBody = ({ children, ...rest }) => (
  <StyledModalBody {...rest}>{children}</StyledModalBody>
);

ModalBody.defaultProps = { align: 'left' };

ModalBody.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node.isRequired
};

export default ModalBody;
