import styled from 'styled-components';
import { Button } from 'components';

export const StyledButton = styled(Button)`
  position: relative;
`;

export const StyledContent = styled.div`
  height: 50px;
  width: 150px;
  background-color: #383838;
  position: absolute;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  margin-top: 10px;
  margin-left: -52px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

export const StyledItem = styled.label`
  height: 50px;
  width: 50px;
  position: relative;
  cursor: pointer;
  background-color: #383838;

  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    filter: brightness(90%);
  }
  &:hover > i {
    color: orange !important;
  }
`;
