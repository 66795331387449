import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  SEND_USER_POST,
  sendUserPostSuccess,
  sendUserPostFailed
} from './sendUserPosts.actions';

function* sendUserPost({ text, media, mediaLink }) {
  try {
    const formData = new FormData();
    formData.set('text', text);
    if (media) formData.set('media', media);
    if (mediaLink) formData.set('mediaLink', mediaLink);
    const newPost = yield apiPost('/api/user/post', formData);
    yield put(sendUserPostSuccess(newPost));
  } catch (error) {
    yield put(sendUserPostFailed(error));
    toast.error(i18n.t('toast:sendPostFailed'));
  }
}

export default function*() {
  yield takeLatest(SEND_USER_POST, sendUserPost);
}
