import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StyledMenuItem from './menuItem.style';

const MenuItem = ({
  orientation,
  children,
  textAlign,
  hoverColor,
  link,
  disabled,
  isActive,
  spacing,
  ...rest
}) =>
  disabled ? (
    <StyledMenuItem
      orientation={orientation}
      textAlign={textAlign}
      hoverColor={hoverColor}
      isActive={isActive}
      disabled={disabled}
      spacing={spacing}
      {...rest}
    >
      {children}
    </StyledMenuItem>
  ) : (
    <Link to={link}>
      <StyledMenuItem
        orientation={orientation}
        textAlign={textAlign}
        hoverColor={hoverColor}
        isActive={isActive}
        disabled={disabled}
        spacing={spacing}
        {...rest}
      >
        {children}
      </StyledMenuItem>
    </Link>
  );

MenuItem.defaultProps = {
  hoverColor: 'orange',
  isActive: false,
  disabled: false,
  spacing: '10px'
};

MenuItem.propTypes = {
  orientation: PropTypes.string,
  children: PropTypes.any,
  textAlign: PropTypes.string,
  hoverColor: PropTypes.string,
  link: PropTypes.string,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  spacing: PropTypes.string
};

export default MenuItem;
