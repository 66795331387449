import { createReducer } from 'utils/reducer';
import {
  GET_CHATS,
  GET_CHATS_SUCCESS,
  GET_CHATS_FAILED
} from './getChats.actions';
import {
  getChats,
  getChatsFailed,
  getChatsSuccess
} from './getChats.reducer.handler';

const initialState = {
  data: [],
  error: null,
  isSuccessful: false,
  isError: false
};

export default createReducer(initialState, {
  [GET_CHATS]: getChats,
  [GET_CHATS_SUCCESS]: getChatsSuccess,
  [GET_CHATS_FAILED]: getChatsFailed
});
