export const EDIT_USER_POST = 'EDIT_USER_POST';
export const EDIT_USER_POST_SUCCESS = 'EDIT_USER_POST_SUCCESS';
export const EDIT_USER_POST_FAILED = 'EDIT_USER_POST_FAILED';

export const editUserPost = (postId, text, media) => ({
  type: EDIT_USER_POST,
  postId,
  text,
  media
});

export const editUserPostSuccess = editedPost => ({
  type: EDIT_USER_POST_SUCCESS,
  payload: { editedPost }
});

export const editUserPostFailed = errors => ({
  type: EDIT_USER_POST_FAILED,
  payload: { errors }
});
