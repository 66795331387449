import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import { SEARCH_GLOBAL, searchGlobalSuccess, searchGlobalFailed } from './globalSearch.actions';

function* searchGlobal({ searchTerm, includeUsers }) {
  try {
    const searchResult = yield apiPost('/api/search/', { searchTerm, user: includeUsers });
    yield put(searchGlobalSuccess(searchResult));
  } catch (error) {
    yield put(searchGlobalFailed(error));
    toast.error(i18n.t('globalSearch:failed'));
  }
}

export default function* () {
  yield takeLatest(SEARCH_GLOBAL, searchGlobal);
}
