import styled from 'styled-components';

export default styled.div`
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ active }) => (active ? 'block' : 'none')};
  z-index: 9998;
`;
