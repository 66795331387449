const localStorageService = {
  setTheme: theme => localStorage.setItem('theme', theme),
  getTheme: () => localStorage.getItem('theme'),
  removeTheme: () => localStorage.getItem('theme'),

  setLocalization: locale => localStorage.setItem('i18nextLng', locale),
  getLocalization: () => localStorage.getItem('i18nextLng'),
  removeLocalization: () => localStorage.getItem('i18nextLng'),
};

export default localStorageService;
