import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EditPost from './EditPost';

const EditPostFormContainer = ({
  baseText,
  baseImage,
  onCancelEdit,
  onSaveEdit,
  ...rest
}) => {
  const [text, setText] = useState(baseText);
  const [image, setImage] = useState({
    image: '',
    preview: baseImage,
  });
  const isImageSet = image.preview && image.preview !== '';
  const { t } = useTranslation('post');
  return (
    <EditPost
      t={t}
      text={text}
      setText={setText}
      image={image.preview}
      setImage={setImage}
      isImageSet={isImageSet}
      onChangeImage={onChangeImage}
      onDeleteImage={onDeleteImage}
      onSave={onSave}
      onCancel={onCancel}
      {...rest}
    />
  );
  function onChangeImage(e) {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setImage({ file, preview: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      setImage({ file: '', preview: '' });
    }
  }
  function onDeleteImage() {
    setImage({ file: '', preview: '' });
  }
  function onSave() {
    if (!text) return;
    onSaveEdit(text, image);
  }
  function onCancel() {
    onCancelEdit();
  }
};

EditPostFormContainer.propTypes = {
  baseText: PropTypes.string.isRequired,
  baseImage: PropTypes.string.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onSaveEdit: PropTypes.func.isRequired,
};

export default EditPostFormContainer;
