import styled, { css } from 'styled-components';
import { Button } from '..';

export const StyledModal = styled.div`
  max-height: 95%;
  background-color: white;
  overflow: hidden;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2),
    1px 3px 15px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.2),
    1px 3px 15px 2px rgba(0, 0, 0, 0.2);
  z-index: 9999;

  ${({ size }) => {
    switch (size) {
      case 'mini':
        return css`
          @media (max-width: 767px) {
            width: 95%;
          }
          @media (min-width: 768px) {
            width: 35.2%;
          }
          @media (min-width: 992px) {
            width: 340px;
          }
          @media (min-width: 1200px) {
            width: 360px;
          }
          @media (min-width: 1920px) {
            width: 380px;
          }
        `;
      case 'tiny':
        return css`
          @media (max-width: 767px) {
            width: 95%;
          }
          @media (min-width: 768px) {
            width: 52.8%;
          }
          @media (min-width: 992px) {
            width: 510px;
          }
          @media (min-width: 1200px) {
            width: 540px;
          }
          @media (min-width: 1920px) {
            width: 570px;
          }
        `;
      case 'small':
        return css`
          @media (max-width: 767px) {
            width: 95%;
          }
          @media (min-width: 768px) {
            width: 70.4%;
          }
          @media (min-width: 992px) {
            width: 680px;
          }
          @media (min-width: 1200px) {
            width: 720px;
          }
          @media (min-width: 1920px) {
            width: 760px;
          }
        `;
      default:
      case 'large':
        return css`
          @media (max-width: 767px) {
            width: 95%;
          }
          @media (min-width: 768px) {
            width: 88%;
          }
          @media (min-width: 992px) {
            width: 960px;
          }
          @media (min-width: 1200px) {
            width: 1040px;
          }
          @media (min-width: 1920px) {
            width: 1120px;
          }
        `;
      case 'fullscreen':
        return css`
          width: 95% !important;
        `;
    }
  }}
`;
export const StyledModalHeader = styled.div`
  background-color: #f2f2f2;
  padding: 1.25em;
  font-weight: bold;
  color: #404040;
  display: flex;
  flex-direction: row;
  ${({ align }) => {
    switch (align) {
      case 'left':
        return css`
          justify-content: flex-start;
        `;
      default:
      case 'center':
        return css`
          justify-content: center;
        `;
      case 'right':
        return css`
          justify-content: flex-end;
        `;
    }
  }}
`;
export const StyledModalBody = styled.div`
  padding: 1.25em;
  color: #0d0d0d;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ align }) => {
    switch (align) {
      case 'left':
        return css`
          justify-content: flex-start;
        `;
      default:
      case 'center':
        return css`
          justify-content: center;
        `;
      case 'right':
        return css`
          justify-content: flex-end;
        `;
    }
  }}
`;
export const StyledModalFooter = styled.div`
  padding: 1.25em;
  color: #404040;
  display: flex;
  flex-direction: row;
  ${({ align }) => {
    switch (align) {
      case 'left':
        return css`
          justify-content: flex-start;
        `;
      default:
      case 'center':
        return css`
          justify-content: center;
        `;
      case 'right':
        return css`
          justify-content: flex-end;
        `;
    }
  }}
`;

export const StyledActionButton = styled(Button)`
  ${({ align }) => {
    switch (align) {
      default:
      case 'left':
      case 'center':
        return css`
          margin-right: 15px;
        `;
      case 'right':
        return css`
          margin-left: 15px;
        `;
    }
  }}
`;
