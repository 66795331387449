import { createReducer } from 'utils/reducer';

import {
  SEND_MESSAGE,
  SEND_MESSAGE_FAILED,
  SEND_MESSAGE_SUCCESS
} from './sendMessage.actions';
import {
  sendMessage,
  sendMessageFailed,
  sendMessageSuccess
} from './sendMessage.reducer.handler';

const initialState = {
  data: {},
  error: null,
  isSuccessful: false,
  isError: false
};

export default createReducer(initialState, {
  [SEND_MESSAGE]: sendMessage,
  [SEND_MESSAGE_FAILED]: sendMessageFailed,
  [SEND_MESSAGE_SUCCESS]: sendMessageSuccess
});
