export const GET_USER = "GET_USER";
export const GET_USER_BY_NAME = "GET_USER_BY_NAME";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILED = "GET_USER_FAILED";

export const getUser = userId => ({
  type: GET_USER,
  userId
});

export const getUserByName = name => ({
  type: GET_USER_BY_NAME,
  name
});

export const getUserSuccess = user => ({
  type: GET_USER_SUCCESS,
  payload: { user }
});

export const getUserFailed = error => ({
  type: GET_USER_FAILED,
  payload: { error }
});
