import { put, takeLatest } from 'redux-saga/effects';
import {
  CONFIRM_EMAIL_REQUEST,
  confirmEmailSuccess,
  confirmEmailFailed,
} from './confirmEmail.actions';
import { apiPost } from 'services/api/restService.service';
import history from 'utils/history';

export function* confirmEmail({ token, email }) {
  try {
    yield apiPost(`/api/account/confirmEmail?token=${token}&email=${email}`);
    yield put(confirmEmailSuccess());
    history.push('/login');
  } catch (error) {
    yield put(confirmEmailFailed(error));
  }
}

export default function* () {
  yield takeLatest(CONFIRM_EMAIL_REQUEST, confirmEmail);
}
