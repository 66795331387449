import styled from 'styled-components';

const StyledBannerContainer = styled.img`
  overflow: hidden;
  height: 300px;
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
`;

export default StyledBannerContainer;
