import React from 'react';
import PropTypes from 'prop-types';

const LandingIconWithText = ({ children, iconPath, heading }) => (
  <>
    <img
      src={iconPath}
      alt="Icon"
      height="75"
      style={{ display: 'block', margin: 'auto' }}
    />
    <div
      style={{
        color: 'white',
        fontWeight: 'bolder',
        textAlign: 'center',
        fontSize: '18px',
        marginBottom: '10px'
      }}
    >
      {heading}
    </div>
    <p style={{ color: 'white', fontWeight: '500', textAlign: 'center', fontSize: '16px'}}>
      {children}
    </p>
  </>
);

LandingIconWithText.propTypes = {
  heading: PropTypes.string.isRequired,
  iconPath: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
};

export default LandingIconWithText;
