export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILED = 'SEND_FEEDBACK_FAILED';

export const sendFeedback = (text, feedbackType) => ({
  type: SEND_FEEDBACK,
  text,
  feedbackType
});

export const sendFeedbackSuccess = feedback => ({
  type: SEND_FEEDBACK_SUCCESS,
  payload: { feedback }
});

export const sendFeedbackFailed = error => ({
  type: SEND_FEEDBACK_FAILED,
  payload: { error }
});
