import { updateObject, updateItemInArray } from '../../../utils';

export function sendPostSuccess(state, payload) {
  return updateObject(state, { data: [payload.newPost, ...state.data] });
}

export function getPostsSuccess(state, payload) {
  return updateObject(state, { data: payload.posts });
}

export function deletePost(state) {
  return updateObject(state, { isDeleting: true });
}

export function deletePostSuccess(state, payload) {
  return updateObject(state, {
    data: state.data.filter(x => x.id !== payload.postId),
    isDeleting: false
  });
}

export function deletePostFailed(state) {
  return updateObject(state, { isDeleting: false });
}

export function likePostSuccess(state, payload) {
  const updatedPostList = updateItemInArray(state.data, payload.postId, post =>
    updateObject(post, {
      likes: [...post.likes, payload.newPostLike]
    })
  );
  return updateObject(state, { data: updatedPostList });
}

export function unlikePostSuccess(state, payload) {
  const updatedPostList = updateItemInArray(state.data, payload.postId, post =>
    updateObject(post, {
      likes: post.likes.filter(
        postLike => postLike.postLikeId !== payload.postLikeId
      )
    })
  );
  return updateObject(state, { data: updatedPostList });
}

export function updatePostSuccess(state, payload) {
  return updateObject(state, {
    data: updateItemInArray(state.data, payload.editedPost.id, post =>
      updateObject(post, {
        text: payload.editedPost.text,
        media: payload.editedPost.media
      })
    )
  });
}
