import { put, takeLatest } from 'redux-saga/effects';
import { apiDelete } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  DECLINE_FRIEND_REQUEST,
  declineFriendRequestSuccess,
  declineFriendRequestFailed
} from './declineFriendRequest.actions';

function* declineFriendRequest({ friendRequestId }) {
  try {
    yield apiDelete(`/api/friends/request/${friendRequestId}`);
    yield put(declineFriendRequestSuccess(friendRequestId));
  } catch (error) {
    yield put(declineFriendRequestFailed(error));
    toast.error(i18n.t('toast:declineFriendRequestFailed'));
  }
}

function* declineFriendRequestSaga() {
  yield takeLatest(DECLINE_FRIEND_REQUEST, declineFriendRequest);
}

export default declineFriendRequestSaga;
