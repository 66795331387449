export const SEND_USER_POST = 'SEND_USER_POST';
export const SEND_USER_POST_SUCCESS = 'SEND_USER_POST_SUCCESS';
export const SEND_USER_POST_FAILED = 'SEND_USER_POST_FAILED';

export const sendUserPost = (text, media, mediaLink) => ({
  type: SEND_USER_POST,
  text,
  media,
  mediaLink
});

export const sendUserPostSuccess = newPost => ({
  type: SEND_USER_POST_SUCCESS,
  payload: { newPost }
});

export const sendUserPostFailed = errors => ({
  type: SEND_USER_POST_FAILED,
  payload: { errors }
});
