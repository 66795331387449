import React from 'react';
import PropTypes from 'prop-types';
import { StyledLabel, StyledIcon } from './EditPostImageButton.style';

const EditPostImageButton = ({
  localId,
  icon,
  isImageSet,
  onDeleteImage,
  onChangeImage
}) => {
  const deleteName = localId + '-delete';
  const addName = localId + '-add';
  return (
    <>
      <StyledLabel
        htmlFor={isImageSet ? deleteName : addName}
        isImageSet={isImageSet}
      >
        <StyledIcon icon={icon} size="25px" />
      </StyledLabel>

      {isImageSet && (
        <input
          id={deleteName}
          type="button"
          style={{ visibility: 'hidden' }}
          onClick={onDeleteImage}
        />
      )}
      {!isImageSet && (
        <input
          id={addName}
          type="file"
          multiple="multiple"
          name="_photos"
          accept="image/*, video/mp4"
          style={{ visibility: 'hidden' }}
          onChange={e => onChangeImage(e)}
        />
      )}
    </>
  );
};

EditPostImageButton.propTypes = {
  icon: PropTypes.string.isRequired,
  isImageSet: PropTypes.bool.isRequired
};

export default EditPostImageButton;
