import React from 'react';
import PropTypes from 'prop-types';

const LandingPageSectionHeader = ({ content }) => (
  <div className="row">
    <LandingPageHeader content={content} />
    <div
      className="col-2"
      style={{
        border: '3px solid #FF7400',
        boxShadow: '0px 0px 5px #FF7400',
        width: '200px',
        margin: 'auto',
        marginTop: '25px'
      }}
    />
  </div>
);

LandingPageSectionHeader.propTypes = { content: PropTypes.string.isRequired };

const LandingPageHeader = ({ content }) => (
  <h3
    className="col-12"
    style={{
      color: 'white',
      fontSize: '40px',
      margin: 'auto',
      textAlign: 'center',
      fontWeight: '400'
    }}
  >
    {content}
  </h3>
);

LandingPageHeader.propTypes = { content: PropTypes.string.isRequired };

export { LandingPageHeader };
export default LandingPageSectionHeader;
