import styled from "styled-components";

const StyledSegment = styled.div`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top-color: rgba(34, 36, 38, 0.15);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgba(34, 36, 38, 0.15);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgba(34, 36, 38, 0.15);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgba(34, 36, 38, 0.15);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  padding: 1em 1em;
  box-sizing: border-box;
`;

export default StyledSegment;
