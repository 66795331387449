import { put, takeLatest } from 'redux-saga/effects';
import { apiDelete } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  DELETE_NOTIFICATION,
  deleteNotificationSuccess,
  deleteNotificationFailed
} from './deleteNotification.actions';

function* deleteNotification({ notificationId }) {
  try {
    const notification = yield apiDelete(
      `/api/notifications/${notificationId}`
    );
    yield put(deleteNotificationSuccess(notification));
  } catch (error) {
    yield put(deleteNotificationFailed(error));
    toast.error(i18n.t('toast:deleteNotificationFailed'));
  }
}

export default function*() {
  yield takeLatest(DELETE_NOTIFICATION, deleteNotification);
}
