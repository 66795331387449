export const DELETE_FRIEND = 'DELETE_FRIEND';
export const DELETE_FRIEND_SUCCESS = 'DELETE_FRIEND_SUCCESS';
export const DELETE_FRIEND_FAILED = 'DELETE_FRIEND_FAILED';

export const deleteFriend = userId => ({
  type: DELETE_FRIEND,
  userId
});

export const deleteFriendSuccess = addedFriend => ({
  type: DELETE_FRIEND_SUCCESS,
  payload: { addedFriend }
});

export const deleteFriendFailed = error => ({
  type: DELETE_FRIEND_FAILED,
  payload: { error }
});
