import { put, takeLatest } from 'redux-saga/effects';
import { apiGet } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  GET_NOTIFICATIONS,
  getNotificationsSuccess,
  getNotificationsFailed
} from './getNotifications.actions';

function* getNotifications() {
  try {
    const notifications = yield apiGet('/api/notifications');
    yield put(getNotificationsSuccess(notifications));
  } catch (error) {
    yield put(getNotificationsFailed(error));
    toast.error(i18n.t('toast:getNotificationsFailed'));
  }
}

export default function*() {
  yield takeLatest(GET_NOTIFICATIONS, getNotifications);
}
