import React from 'react';
import PropTypes from 'prop-types';

const LandingHeader = ({ clientUrl }) => (
  <div className="row px-5">
    <div className="col-sm-6 col-12 text-center text-md-left">
      <a href={`${clientUrl}/welcome`}>
        <img src={`${clientUrl}/images/logo.png`} alt="Logo" className="logo" />
        <h1 className="logo_text">Acety</h1>
      </a>
    </div>
    <div className="col-sm-6 col-12 py-3 my-auto text-center text-md-right">
      <a className="landing_button" href={`${clientUrl}/login`}>
        LOGIN
      </a>
      <div className="w-100 d-block d-md-none mt-4 mt-md-none" />
      <a className="landing_button mx-4" href={`${clientUrl}/register`}>
        SIGN UP
      </a>
    </div>
  </div>
);

LandingHeader.propTypes = { clientUrl: PropTypes.string.isRequired };

export default LandingHeader;
