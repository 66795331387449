import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';

const defaultAvatar = `${process.env.PUBLIC_URL}/images/avatar_placeholder.png`;

const Avatar = ({ rounded, image, size, alt, ...rest }) => {
  return (
    <Image
      src={image || defaultAvatar}
      height={size}
      width={size}
      rounded={rounded}
      alt={alt}
      {...rest}
    />
  );
};

Avatar.propTypes = {
  image: PropTypes.string,
  rounded: PropTypes.bool,
  size: PropTypes.string,
  alt: PropTypes.string
};

Avatar.defaultProps = {
  image: defaultAvatar,
  size: '150px',
  rounded: true,
  alt: 'avatar'
};

export default Avatar;
