const setupAxios = (request) => {
  request.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        'access_token'
      )}`;
      config.withCredentials = false;
      return config;
    },
    (error) => Promise.reject(error)
  );
  // request.interceptors.response.use(undefined, (error) => {
  //   const {
  //     config,
  //     response: { status },
  //   } = error;
  //   const orginalRequest = config;
  //   if (status === 401) {
  //     if (!isRefreshing) {
  //       isRefreshing = true;
  //       userManager
  //         .signinSilent()
  //         .then((user) => {
  //           onRefreshed(user.access_token);
  //           isRefreshing = false;
  //           subscribers = [];
  //         })
  //         .catch((e) => console.log('Error', e));
  //     }
  //     const requestSubscribers = new Promise((resolve) => {
  //       subscribeTokenRefresh((token) => {
  //         orginalRequest.headers.Authorization = `Bearer ${token}`;
  //         resolve(API(orginalRequest));
  //       });
  //     });
  //     return requestSubscribers;
  //   } else if (isRefreshing) {
  //     Promise.reject(error);
  //   }
  //   return Promise.reject(error);
  // });
};

export default setupAxios;
