import { createReducer } from 'utils/reducer';

import {
  SEARCH_GLOBAL,
  SEARCH_GLOBAL_SUCCESS,
  SEARCH_GLOBAL_FAILED
} from './globalSearch.actions';
import {
  searchGlobal,
  searchGlobalSuccess,
  searchGlobalFailed
} from './globalSearch.reducer.handler';

const initialState = {
  data: { searchResults: { users: [] } },
  error: null,
  isSuccessful: false,
  isError: false
};

export default createReducer(initialState, {
  [SEARCH_GLOBAL]: searchGlobal,
  [SEARCH_GLOBAL_SUCCESS]: searchGlobalSuccess,
  [SEARCH_GLOBAL_FAILED]: searchGlobalFailed
});
