import { SEARCH_USER_SUCCESS } from './searchUser/searchUser.actions';

const initialState = [];

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SEARCH_USER_SUCCESS: {
      return Object.assign([], payload.results);
    }
    default:
      return state;
  }
};
