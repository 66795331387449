import { createReducer } from 'utils/reducer';

import { GET_CHATS_SUCCESS } from './getChats/getChats.actions';
import { GET_CHAT_MESSAGES_SUCCESS } from './getChatMessages/getChatMessages.actions';
import { READ_CHAT_SUCCESS } from './readChat/readChat.actions';
import { GET_CHAT_MESSAGE_SUCCESS } from './getChatMessage/getChatMessage.actions';
import { SEND_MESSAGE_SUCCESS } from './sendMessage/sendMessage.actions';
import { CREATE_CHAT_SUCCESS } from './createChat/createChat.actions';
import { DELETE_CHAT_SUCCESS } from './deleteChat/deleteChat.actions';

import {
  getChats,
  getChatMessages,
  readChat,
  getChatMessage,
  sendMessage,
  createChat,
  deleteChat
} from './chat.reducer.handler';

const initialState = [];

export default createReducer(initialState, {
  [GET_CHATS_SUCCESS]: getChats,
  [GET_CHAT_MESSAGES_SUCCESS]: getChatMessages,
  [READ_CHAT_SUCCESS]: readChat,
  [GET_CHAT_MESSAGE_SUCCESS]: getChatMessage,
  [SEND_MESSAGE_SUCCESS]: sendMessage,
  [CREATE_CHAT_SUCCESS]: createChat,
  [DELETE_CHAT_SUCCESS]: deleteChat
});
