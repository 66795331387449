import React from 'react';
import PropTypes from 'prop-types';
import { StyledModalHeader } from './modal.style';

const ModalHeader = ({ children, ...rest }) => {
  return <StyledModalHeader {...rest}>{children}</StyledModalHeader>;
};

ModalHeader.defaultProps = {
  children: null,
  align: 'left'
};

ModalHeader.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node
};

export default ModalHeader;
