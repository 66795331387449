import { GET_USER_SUCCESS } from './getUser/getUser.actions';
const initialState = {};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_USER_SUCCESS: {
      return Object.assign({}, state, payload.user);
    }
    default:
      return state;
  }
};
