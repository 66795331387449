import React from 'react';
import PropTypes from 'prop-types';
import StyledBannerContainer from './banner.style';

const Banner = ({ image, ...rest }) => (
  <StyledBannerContainer
    src={
      image ? image : process.env.PUBLIC_URL + '/images/banner_placeholder.png'
    }
    alt="banner"
    {...rest}
  />
);

Banner.propTypes = {
  image: PropTypes.string.isRequired
};

export default Banner;
