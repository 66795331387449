import React from 'react';
import PropTypes from 'prop-types';
import StyledTooltip from './tooltip.style';

const Tooltip = ({ children, text, position, ...rest }) => {
  return (
    <StyledTooltip position={position} {...rest}>
      {children}
      <div className="tooltiptext">{text}</div>
    </StyledTooltip>
  );
};

Tooltip.defaultProps = {
  position: 'top'
};

Tooltip.propTypes = {
  children: PropTypes.any,
  text: PropTypes.string.isRequired,
  position: PropTypes.string
};

export default Tooltip;
