import React from "react";
import PropTypes from "prop-types";
import StyledComponent from "./segment.style";

const Segment = ({ children, backgroundColor, color, ...rest }) => (
  <StyledComponent backgroundColor={backgroundColor} color={color} {...rest}>
    {children}
  </StyledComponent>
);

Segment.defaultProps = {
  backgroundColor: "#1c1c1c",
  color: "white"
};

Segment.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default Segment;
