export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_REQUEST_SUCCESS = 'REGISTER_REQUEST_SUCCESS';
export const REGISTER_REQUEST_FAILED = 'REGISTER_REQUEST_FAILED';

export const registerRequest = (username, password, email) => ({
  type: REGISTER_REQUEST,
  username,
  password,
  email,
});

export const registerSuccess = () => ({
  type: REGISTER_REQUEST_SUCCESS,
  payload: { success: true },
});

export const registerFailed = (error) => ({
  type: REGISTER_REQUEST_FAILED,
  payload: { error, success: false },
});
