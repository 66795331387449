import React from 'react';
import PropTypes from 'prop-types';
import { StyledGrid, StyledGridColumn, StyledGridRow } from './grid.style';

const Grid = ({ children, ...rest }) => {
  return <StyledGrid {...rest}>{children}</StyledGrid>;
};

Grid.propTypes = {
  children: PropTypes.object.isRequired
};

const GridRow = ({ children, columnSpacing, columnBasis, ...rest }) => {
  const columnsWithSpacing = React.Children.map(children, child =>
    React.cloneElement(child, {
      columnSpacing: columnSpacing,
      columnBasis: columnBasis
    })
  );
  return <StyledGridRow {...rest}>{columnsWithSpacing}</StyledGridRow>;
};

GridRow.defaultProps = {
  columnSpacing: 20,
  columnBasis: 12
};

GridRow.propTypes = {
  children: PropTypes.any.isRequired,
  columnSpacing: PropTypes.number,
  columnBasis: PropTypes.number
};

const GridColumn = ({ children, width, ...rest }) => {
  return (
    <StyledGridColumn {...rest} width={width}>
      {children}
    </StyledGridColumn>
  );
};

GridColumn.defaultProps = {
  width: 3
};

GridColumn.propTypes = {
  children: PropTypes.any.isRequired,
  width: PropTypes.number
};

export { Grid, GridRow, GridColumn };
