import { SEND_USER_POST_SUCCESS } from './sendUserPost/sendUserPosts.actions';
import { GET_USER_POSTS_SUCCESS } from './getUserPosts/getUserPosts.actions';
import { LIKE_USER_POST_SUCCESS } from './likeUserPost/likeUserPost.actions';
import { UNLIKE_USER_POST_SUCCESS } from './unlikeUserPost/unlikeUserPost.actions';
import { EDIT_USER_POST_SUCCESS } from './editUserPost/editUserPost.actions';
import {
  DELETE_USER_POST_SUCCESS,
  DELETE_USER_POST,
  DELETE_USER_POST_FAILED
} from './deleteUserPost/deleteUserPost.actions';
import {
  sendPostSuccess,
  getPostsSuccess,
  deletePost,
  deletePostSuccess,
  deletePostFailed,
  likePostSuccess,
  unlikePostSuccess,
  updatePostSuccess
} from './utils';

const initialState = { data: [], isDeleting: false };

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SEND_USER_POST_SUCCESS:
      return sendPostSuccess(state, payload);
    case GET_USER_POSTS_SUCCESS:
      return getPostsSuccess(state, payload);
    case DELETE_USER_POST:
      return deletePost(state);
    case DELETE_USER_POST_SUCCESS:
      return deletePostSuccess(state, payload);
    case DELETE_USER_POST_FAILED:
      return deletePostFailed(state);
    case LIKE_USER_POST_SUCCESS:
      return likePostSuccess(state, payload);
    case UNLIKE_USER_POST_SUCCESS:
      return unlikePostSuccess(state, payload);
    case EDIT_USER_POST_SUCCESS:
      return updatePostSuccess(state, payload);
    default:
      return state;
  }
};
