import React, {
  cloneElement,
  Children,
  useState,
  useImperativeHandle,
  forwardRef
} from 'react';
import PropTypes from 'prop-types';
import { Dimmer, OutsideClickDetector } from '..';
import { StyledModal } from './modal.style';

import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const Modal = forwardRef(
  ({ children, size, trigger, dimmer, ...rest }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    useImperativeHandle(ref, () => ({ show: () => show() }));
    useImperativeHandle(ref, () => ({ hide: () => hide() }));
    return (
      <>
        {dimmer && <Dimmer active={isOpen} />}
        {trigger && cloneElement(trigger, { onClick: show })}
        {isOpen && (
          <OutsideClickDetector handleClickOutside={hide}>
            <StyledModal size={size} {...rest}>
              {Children.map(children, child => {
                switch (child.type.displayName) {
                  case 'ModalHeader':
                    return cloneElement(child);
                  case 'ModalBody':
                    return cloneElement(child);
                  case 'ModalFooter':
                    return cloneElement(child);
                  default:
                    return child;
                }
              })}
            </StyledModal>
          </OutsideClickDetector>
        )}
      </>
    );
    function show() {
      setIsOpen(true);
    }
    function hide() {
      setIsOpen(false);
    }
  }
);

Modal.defaultProps = {
  size: 'large',
  dimmer: true,
  children: null
};

Modal.propTypes = {
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'fullscreen']),
  trigger: PropTypes.node.isRequired,
  dimmer: PropTypes.bool,
  children: PropTypes.node
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
