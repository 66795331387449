import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  DELETE_FRIEND,
  deleteFriendSuccess,
  deleteFriendFailed
} from './deleteFriend.actions';

function* deleteFriend({ userId }) {
  try {
    const friend = yield apiPost(`/api/friends/request/${userId}`);
    yield put(deleteFriendSuccess(friend));
  } catch (error) {
    yield put(deleteFriendFailed(error));
    toast.error(i18n.t('toast:deleteFriendFailed'));
  }
}

export default function*() {
  yield takeLatest(DELETE_FRIEND, deleteFriend);
}
