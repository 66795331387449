import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Login from './login';
import { loginRequest } from 'store/login/login.actions';
import store from 'store/store';

const LoginContainer = () => {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  const handleLogin = () => {
    console.log(username);
    store.dispatch(loginRequest(username, password, true));
  };

  return (
    <Login {...{ username, password, handleLogin, setPassword, setUsername }} />
  );
};

LoginContainer.propTypes = {
  location: PropTypes.object.isRequired,
};

export default LoginContainer;
