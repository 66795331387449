import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledSearchBarInput, StyledSearchBarIcon } from './searchBar.style';
import OutsideClickDetector from '../../OutsideClickDetector/OutsideClickDetector.component';

const SearchBar = ({ onChange, children }) => {
  const [showResults, toggleShowResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <OutsideClickDetector handleClickOutside={() => toggleShowResults(false)}>
      <div style={{ position: 'relative' }}>
        <StyledSearchBarInput
          placeholder="Search..."
          onChange={e => {
            setSearchTerm(e.target.value);
            onChange(e.target.value);
          }}
          onClick={() => toggleShowResults(true)}
          value={searchTerm}
        />

        <StyledSearchBarIcon size="25px" icon="search" />
      </div>
      {showResults && children}
    </OutsideClickDetector>
  );
};

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired
};

export default SearchBar;
