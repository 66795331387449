import { updateObject } from 'utils/reducer';

export function getChatMessages(state) {
  return updateObject(state, {
    data: [],
    error: null,
    isSuccessful: false,
    isError: false
  });
}

export function getChatMessagesSuccess(state, { payload }) {
  return updateObject(state, {
    data: payload.messages,
    isSuccessful: true
  });
}

export function getChatMessagesFailed(state, { payload }) {
  return updateObject(state, {
    error: payload.error,
    isError: true
  });
}
