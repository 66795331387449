import React from 'react';
import PropTypes from 'prop-types';
import { StyledYoutubeWrapper, StyledYoutubePlayer } from './Media.style';

const MediaYoutube = ({ src, ...rest }) => (
  <StyledYoutubeWrapper>
    <StyledYoutubePlayer
      url={src}
      height="100%"
      width="100%"
      preload
      controls
      {...rest}
    />
  </StyledYoutubeWrapper>
);

MediaYoutube.propTypes = { src: PropTypes.string.isRequired };

export default MediaYoutube;
