export const REMOVE_FRIEND = 'REMOVE_FRIEND';
export const REMOVE_FRIEND_SUCCESS = 'REMOVE_FRIEND_SUCCESS';
export const REMOVE_FRIEND_FAILED = 'REMOVE_FRIEND_FAILED';

export const removeFriend = friendId => ({
  type: REMOVE_FRIEND,
  friendId
});

export const removeFriendSuccess = friendId => ({
  type: REMOVE_FRIEND_SUCCESS,
  payload: { friendId }
});

export const removeFriendFailed = error => ({
  type: REMOVE_FRIEND_FAILED,
  payload: { error }
});
