import React from 'react';
import PropTypes from 'prop-types';
import '../scrollDownAnimation.css';

const LandingPageScrollDown = ({ scrollTo }) => (
  <div
    className="d-none d-md-block"
    style={{
      position: 'absolute',
      left: '50%',
      bottom: '-20px',
      msTransform: 'translate(-50%, -50%)',
      transform: 'translate(-50%, -50%)'
    }}
     >
    <div className="row justify-content-center" style={{ marginTop: '100px' }}>
      <div className="scrollDownLabel" onClick={() => scrollTo()}>
        Learn more
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="chevronContainer" onClick={() => scrollTo()}>
        <div className="chevron" />
        <div className="chevron" />
        <div className="chevron" />
      </div>
    </div>
  </div>
);

LandingPageScrollDown.propTypes = { scrollTo: PropTypes.func.isRequired };

export default LandingPageScrollDown;
