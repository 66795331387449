export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';

export const sendMessage = (chatId, text) => ({
  type: SEND_MESSAGE,
  chatId,
  text
});

export const sendMessageSuccess = (newMessage, chatId) => ({
  type: SEND_MESSAGE_SUCCESS,
  payload: { newMessage, chatId }
});

export const sendMessageFailed = error => ({
  type: SEND_MESSAGE_FAILED,
  payload: { error }
});
