export const GET_USER_POSTS = 'GET_USER_POSTS';
export const GET_USER_POSTS_SUCCESS = 'GET_USER_POSTS_SUCCESS';
export const GET_USER_POSTS_FAILED = 'GET_USER_POSTS_FAILED';

export const getUserPosts = userId => ({
  type: GET_USER_POSTS,
  userId
});

export const getUserPostsSuccess = posts => ({
  type: GET_USER_POSTS_SUCCESS,
  payload: { posts }
});

export const getUserPostsFailed = error => ({
  type: GET_USER_POSTS_FAILED,
  payload: { error }
});
