export const DELETE_USER_POST = 'DELETE_USER_POST';
export const DELETE_USER_POST_SUCCESS = 'DELETE_USER_POST_SUCCESS';
export const DELETE_USER_POST_FAILED = 'DELETE_USER_POST_FAILED';

export const deleteUserPost = postId => ({
  type: DELETE_USER_POST,
  postId
});

export const deleteUserPostsSuccess = postId => ({
  type: DELETE_USER_POST_SUCCESS,
  payload: { postId }
});

export const deleteUserPostsFailed = error => ({
  type: DELETE_USER_POST_FAILED,
  payload: { error }
});
