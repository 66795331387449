import { put, takeLatest } from 'redux-saga/effects';
import { apiGet } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  GET_FRIEND_REQUESTS,
  getFriendRequestsSuccess,
  getFriendRequestsFailed
} from './getFriendRequests.actions';

function* getFriendRequests() {
  try {
    const ownUser = yield apiGet('/api/friends/request');
    yield put(getFriendRequestsSuccess(ownUser));
  } catch (error) {
    yield put(getFriendRequestsFailed(error));
    toast.error(i18n.t('toast:getFriendRequestsFailed'));
  }
}

export default function*() {
  yield takeLatest(GET_FRIEND_REQUESTS, getFriendRequests);
}
