import { put, takeLatest } from 'redux-saga/effects';
import { apiGet } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  GET_USER,
  GET_USER_BY_NAME,
  getUserSuccess,
  getUserFailed
} from './getUser.actions';

function* getUser({ id }) {
  try {
    const user = yield apiGet(`/api/user/${id}`);
    yield put(getUserSuccess(user));
  } catch (error) {
    yield put(getUserFailed(error));
    toast.error(i18n.t('toast:getUserFailed'));
  }
}

function* getUserByName({ name }) {
  try {
    const user = yield apiGet(`/api/user/${name}`);
    yield put(getUserSuccess(user));
  } catch (error) {
    yield put(getUserFailed(error));
    toast.error(i18n.t('toast:getUserFailed'));
  }
}

function* getUserSaga() {
  yield takeLatest(GET_USER, getUser);
}

function* getUserByNameSaga() {
  yield takeLatest(GET_USER_BY_NAME, getUserByName);
}

export { getUserSaga, getUserByNameSaga };
