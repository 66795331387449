import styled, { css } from 'styled-components';

const StyledUserPreview = styled.div`
  height: 50px;
  cursor: pointer;
  background-color: ${({ transparent, theme }) =>
    (transparent ? theme.colors.transparent : theme.colors.natural[300])};
  line-height: 50px;
  display: flex;
  position: relative;
  &:hover {
    background-color: ${({ theme }) => theme.colors.basic[20]};
    border-radius: 10px;
  }
  ${({ compact }) =>
    compact
    && css`
      width: 50px !important;
    `}
`;

export default StyledUserPreview;
