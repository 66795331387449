import React from 'react';
import PropTypes from 'prop-types';

const LandingFooter = ({ clientUrl }) => (
  <div
    style={{
      backgroundColor: '#242322',
      height: '200px',
      position: 'relative',
    }}
  >
    <div
      style={{
        margin: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        msTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
      }}
    >
      <div
        onClick={() => window.open('https://www.shadet.de/impressum', '_blank')}
        style={{
          color: 'white',
          fontWeight: 'bold',
          fontFamily: 'Source Sans Pro',
          textAlign: 'center',
          fontSize: '24px',
          cursor: 'pointer',
        }}
      >
        Imprint
      </div>
      <a
        href={`${clientUrl}/data-policy/ext`}
        style={{
          textDecoration: 'none',
          color: 'white',
          fontWeight: 'bold',
          fontFamily: 'Source Sans Pro',
          textAlign: 'center',
          fontSize: '24px',
          cursor: 'pointer',
        }}
      >
        Data Policy
      </a>
    </div>
  </div>
);

LandingFooter.propTypes = { clientUrl: PropTypes.string.isRequired };

export default LandingFooter;
