import React from 'react';
import PropTypes from 'prop-types';
import { StyledIcon } from './icon.style';

const Icon = ({ icon, size, color, background, className, ...rest }) => {
  return (
    <StyledIcon
      className={`fa fa-${icon} ${className && className}`}
      size={size}
      color={color}
      {...rest}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  className: PropTypes.string
};

Icon.defaultProps = {
  icon: 'home',
  size: '30px',
  color: 'white',
  background: 'transparent'
};

export default Icon;
