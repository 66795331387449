export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_FAILED = 'READ_NOTIFICATION_FAILED';

export const readNotification = notificationId => ({
  type: READ_NOTIFICATION,
  notificationId
});

export const readNotificationSuccess = notification => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload: { notification }
});

export const readNotificationFailed = error => ({
  type: READ_NOTIFICATION_FAILED,
  payload: { error }
});
