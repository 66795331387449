import { updateObject } from 'utils/reducer';

export function createChat(state) {
  return updateObject(state, {
    data: {},
    error: null,
    isSuccessful: false,
    isError: false
  });
}

export function createChatSuccess(state, { payload }) {
  return updateObject(state, {
    data: payload.chat,
    isSuccessful: true
  });
}

export function createChatFailed(state, { payload }) {
  return updateObject(state, {
    error: payload.error,
    isError: true
  });
}
