import { put, takeLatest } from 'redux-saga/effects';
import { apiDelete } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  REMOVE_FRIEND,
  removeFriendSuccess,
  removeFriendFailed
} from './removeFriend.actions';

function* removeFriend({ friendId }) {
  try {
    yield apiDelete(`/api/friends/${friendId}`);
    yield put(removeFriendSuccess(friendId));
  } catch (error) {
    yield put(removeFriendFailed(error));
    toast.error(i18n.t('toast:removeFriendFailed'));
  }
}

function* removeFriendSaga() {
  yield takeLatest(REMOVE_FRIEND, removeFriend);
}

export default removeFriendSaga;
