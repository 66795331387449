import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  REPORT_USER_POST,
  reportUserPostSuccess,
  reportUserPostFailed
} from './reportUserPost.actions';

function* getUserPosts({ postId, text, reason }) {
  try {
    yield apiPost('/api/post/report', { text, reason, postId });
    yield put(reportUserPostSuccess());
  } catch (error) {
    yield put(reportUserPostFailed(error));
    toast.error(i18n.t('toast:reportPostFailed'));
  }
}

export default function*() {
  yield takeLatest(REPORT_USER_POST, getUserPosts);
}
