import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import history from 'utils/history';
import StyledUserPreview from './userPreview.style';
import Avatar from '../../Images/Avatar';

const UserPreview = ({
  username,
  usernameBold,
  usernameColor,
  avatar,
  compact,
  transparent,
  children,
  noLink,
  ...rest
}) => {
  if (noLink) {
    return (
      <StyledUserPreview compact={compact} transparent={transparent} {...rest}>
        <Avatar image={avatar} size="38px" style={{ margin: '6px' }} />
        <div
          style={{
            color: usernameColor,
            fontWeight: usernameBold ? 'bold' : 'unset'
          }}
        >
          {!compact && username}
        </div>
        {children}
      </StyledUserPreview>
    );
  }
  return (
    <StyledUserPreview
      compact={compact}
      transparent={transparent}
      {...rest}
      onClick={() => {
        history.push(`/user/${username}`);
      }}
    >
      <Avatar image={avatar} size="38px" style={{ margin: '6px' }} />
      <Link
        to={`/user/${username}`}
        style={{
          color: usernameColor,
          fontWeight: usernameBold ? 'bold' : 'unset',
          textDecoration: 'none'
        }}
      >
        {!compact && username}
      </Link>
      {children}
    </StyledUserPreview>
  );
};

UserPreview.propTypes = {
  avatar: PropTypes.string,
  username: PropTypes.string.isRequired,
  compact: PropTypes.bool,
  transparent: PropTypes.bool,
  usernameBold: PropTypes.bool,
  usernameColor: PropTypes.string,
  children: PropTypes.object,
  noLink: PropTypes.bool
};

UserPreview.defaultProps = {
  compact: false,
  transparent: false,
  usernameColor: 'white',
  usernameBold: false,
  noLink: false
};

export default UserPreview;
