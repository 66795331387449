export const GET_FRIENDS = "GET_FRIENDS";
export const GET_FRIENDS_SUCCESS = "GET_FRIENDS_SUCCESS";
export const GET_FRIENDS_FAILED = "GET_FRIENDS_FAILED";

export const getFriends = userId => ({
  type: GET_FRIENDS,
  userId
});

export const getFriendsSuccess = friends => ({
  type: GET_FRIENDS_SUCCESS,
  payload: { friends }
});

export const getFriendsFailed = error => ({
  type: GET_FRIENDS_FAILED,
  payload: { error }
});
