import { updateObject } from 'utils/reducer';

export function deleteChat(state) {
  return updateObject(state, {
    data: {},
    error: null,
    isSuccessful: false,
    isError: false
  });
}

export function deleteChatSuccess(state) {
  return updateObject(state, {
    isSuccessfil: true
  });
}

export function deleteChatFailed(state, { payload }) {
  return updateObject(state, {
    error: payload.error,
    isError: true
  });
}
