import styled from 'styled-components';
import { StyledSearchBarInput, StyledSearchBarIcon } from '../SearchBar/searchBar.style';
import Icon from '../../Icon';

const boxShadow = 'box-shadow: 0 3px 10px #262626;';

const StyledGlobalSearchbar = styled(StyledSearchBarInput)`
  position: relative;
  background-color: #262626;
  height: 50px;
  ${boxShadow}
  padding: 0 25px;
`;

const StyledGlobalSearchbarUnderline = styled.div`
  position: absolute;
  background-color: white;
  height: 1px;
  width: 100%;
  z-index: 2000;
  bottom: 12px;
  margin-right: 20px;
  margin-left: 20px;
  width: 80%;
`;

const StyledGlobalSearchIcon = styled(StyledSearchBarIcon)`
  top: 22px;
  cursor: pointer;
`;

const StyledGlobalSearchIconToggle = styled(Icon)`
  cursor: pointer;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  background-color: #262626;
  color: white;
  border-radius: 50%;
  ${boxShadow}
  padding: 15px 14px 15px 15px;
`;

export {
  StyledGlobalSearchbar,
  StyledGlobalSearchbarUnderline,
  StyledGlobalSearchIcon,
  StyledGlobalSearchIconToggle
};
