export default {
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  24: '24px',
  30: '40px',
  36: '36px',
  48: '48px',
  60: '60px',
  72: '72px'
};
