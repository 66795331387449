import React from 'react';
import PropTypes from 'prop-types';
import StyledImage from './image.style';

const Image = props => <StyledImage {...props} />;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  alt: PropTypes.string,
  rounded: PropTypes.bool,
  fluid: PropTypes.bool
};

Image.defaultProps = {
  height: '150px',
  width: '150px',
  alt: 'image',
  rounded: false,
  fluid: false
};

export default Image;
