import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from './button.style';

const Button = props => <StyledButton {...props} />;

Button.defaultProps = {
  size: 'medium',
  depiction: 'bordered',
  hierarchie: 'primary',
  fluid: false,
  disabled: false
};

Button.propTypes = {
  size: PropTypes.oneOf(['big', 'large', 'medium', 'small']),
  depiction: PropTypes.oneOf(['default', 'bordered', 'pill']),
  hierarchie: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  children: PropTypes.any,
  color: PropTypes.string,
  textColor: PropTypes.string,
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;
