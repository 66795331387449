export const GET_OWN_FRIENDS = 'GET_OWN_FRIENDS';
export const GET_OWN_FRIENDS_SUCCESS = 'GET_OWN_FRIENDS_SUCCESS';
export const GET_OWN_FRIENDS_FAILED = 'GET_OWN_FRIENDS_FAILED';

export const getOwnFriends = () => ({
  type: GET_OWN_FRIENDS
});

export const getOwnFriendsSuccess = ownFriends => ({
  type: GET_OWN_FRIENDS_SUCCESS,
  payload: { ownFriends }
});

export const getOwnFriendsFailed = error => ({
  type: GET_OWN_FRIENDS_FAILED,
  payload: { error }
});
