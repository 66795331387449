import { put, takeLatest } from 'redux-saga/effects';
import { apiGet } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  GET_REQUESTED_FRIENDS,
  getRequestedFriendsSuccess,
  getRequestedFriendsFailed
} from './getRequestedFriends.actions';

function* getRequestedFriends() {
  try {
    const requestedUsers = yield apiGet('/api/friends/request/requested');
    yield put(getRequestedFriendsSuccess(requestedUsers));
  } catch (error) {
    yield put(getRequestedFriendsFailed(error));
    toast.error(i18n.t('toast:getRequestedFriendsFailed'));
  }
}

export default function*() {
  yield takeLatest(GET_REQUESTED_FRIENDS, getRequestedFriends);
}
