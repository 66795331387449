import styled from 'styled-components';

const StyledDock = styled.div`
  border-radius: 5px;
`;

const StyledDockBody = styled.div`
  background-color: ${({ theme }) => theme.colors.natural[400]};
  color: ${({ theme }) => theme.foreground};
  height: 100%;
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? '20px' : '0px')};
`;

export { StyledDock, StyledDockBody };
