import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Dropdown } from 'components';
import TextArea from 'components/TextArea/TextArea';

const ReportPostModal = ({
  t,
  trigger,
  reportText,
  reportReason,
  onChangeText,
  onChangeReason,
  onSendReport,
  reportReasonTypes
}) => {
  const modalRef = useRef();
  return (
    <Modal ref={modalRef} trigger={trigger}>
      <Modal.Header>{t('modal.header')}</Modal.Header>
      <Modal.Body>
        <div
          style={{
            color: 'black',
            marginTop: '20px',
            width: '100%'
          }}
        >
          <Dropdown
            options={reportReasonTypes}
            itemSelected={option => onChangeReason(option)}
          >
            <Button>{reportReason.text}</Button>
          </Dropdown>
          <TextArea
            value={reportText}
            onChange={onChangeText}
            rows={10}
            style={{
              backgroundColor: 'transparent',
              color: 'black',
              marginTop: '20px'
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        actions={[
          {
            key: 'cancel',
            textColor: '#414042',
            hierarchie: 'tertiary',
            children: t('modal.cancel'),
            onClick: () => {
              modalRef.current.hide();
            }
          },
          {
            key: 'submit',
            color: '#4BB462',
            children: t('modal.submit'),
            onClick: () => {
              onSendReport();
              modalRef.current.hide();
            }
          }
        ]}
      />
    </Modal>
  );
};

ReportPostModal.propTypes = {
  t: PropTypes.func.isRequired,
  reportText: PropTypes.string.isRequired,
  reportReason: PropTypes.object.isRequired,
  reportReasonTypes: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onSendReport: PropTypes.func.isRequired,
  onChangeReason: PropTypes.func.isRequired
};

export default ReportPostModal;
