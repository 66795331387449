import { fork, all } from 'redux-saga/effects';
import getOwnFriends from './friends/ownFriends/getOwnFriends/getOwnFriends.saga';
import getNotifications from './notifications/getNotifications/getNotifications.saga';
import getOwnUser from './user/ownUser/getOwnUser/getOwnUser.saga';
import {
  getUserByNameSaga as GetUserByName,
  getUserSaga as getUser,
} from './user/user/getUser/getUser.saga';
import getFriends from './friends/friends/getFriends/getFriends.saga';
import addFriend from './friends/friends/addFriend/addFriend.saga';
import searchUser from './user/user/searchUser/searchUser.saga';
import search from './globalSearch/globalSearch.saga';
import getFriendRequests from './friends/friendRequests/getFriendRequests/getFriendRequests.saga';
import acceptFriendRequest from './friends/friendRequests/acceptFriendRequest/acceptFriendRequest.saga';
import declineFriendRequest from './friends/friendRequests/declineFriendRquest/declineFriendRequest.saga';
import getRequestedFriends from './friends/requestedFriends/getRequestedFriends/getRequestedFriends.saga';
import updateUserProfile from './user/user/updateUserProfile/updateUserProfile.saga';
import sendFeedback from './feedback/sendFeedback/sendFeedback.saga';
import deleteFriend from './friends/friends/deleteFriend/deleteFriend.saga';
import deleteNotification from './notifications/deleteNotification/deleteNotification.saga';
import readNotification from './notifications/readNotification/readNotification.saga';
import sendPost from './posts/userPosts/sendUserPost/sendUserPosts.saga';
import getUserPosts from './posts/userPosts/getUserPosts/getUserPosts.saga';
import deleteUserPost from './posts/userPosts/deleteUserPost/deleteUserPost.saga';
import likeUserPost from './posts/userPosts/likeUserPost/likeUserPost.saga';
import unlikeUserPost from './posts/userPosts/unlikeUserPost/unlikeUserPost.saga';
import editUserPost from './posts/userPosts/editUserPost/editUserPost.saga';
import removeFriend from './friends/friends/removeFriend/removeFriend.saga';
import reportUserPost from './posts/userPosts/reportUserPost/reportUserPost.saga';

import getChats from './chat/getChats/getChats.saga';
import getChatMessages from './chat/getChatMessages/getChatMessages.saga';
import readChat from './chat/readChat/readChat.saga';
import sendMessage from './chat/sendMessage/sendMessage.saga';
import createChat from './chat/createChat/createChat.saga';
import deleteChat from './chat/deleteChat/deleteChat.saga';
import login from './login/login.saga';
import register from './register/register.saga';
import confirmEmail from './confirmEmail/confirmEmail.saga';

export default function* () {
  yield all([
    fork(getUser),
    fork(GetUserByName),
    fork(updateUserProfile),
    fork(getOwnUser),
    fork(getFriends),
    fork(getOwnFriends),
    fork(addFriend),
    fork(getFriendRequests),
    fork(acceptFriendRequest),
    fork(declineFriendRequest),
    fork(getRequestedFriends),
    fork(getNotifications),
    fork(searchUser),
    fork(search),
    fork(sendFeedback),
    fork(deleteFriend),
    fork(deleteNotification),
    fork(readNotification),
    fork(getChats),
    fork(getChatMessages),
    fork(readChat),
    fork(sendMessage),
    fork(createChat),
    fork(deleteChat),
    fork(sendPost),
    fork(getUserPosts),
    fork(deleteUserPost),
    fork(likeUserPost),
    fork(unlikeUserPost),
    fork(editUserPost),
    fork(removeFriend),
    fork(reportUserPost),
    fork(login),
    fork(register),
    fork(confirmEmail),
  ]);
}
