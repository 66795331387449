export const REPORT_USER_POST = 'REPORT_USER_POST';
export const REPORT_USER_POST_SUCCESS = 'REPORT_USER_POST_SUCCESS';
export const REPORT_USER_POST_FAILED = 'REPORT_USER_POST_FAILED';

export const reportUserPost = (postId, text, reason) => ({
  type: REPORT_USER_POST,
  postId,
  text,
  reason
});

export const reportUserPostSuccess = () => ({
  type: REPORT_USER_POST_SUCCESS
});

export const reportUserPostFailed = error => ({
  type: REPORT_USER_POST_FAILED,
  payload: { error }
});
