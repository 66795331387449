import { GET_NOTIFICATIONS_SUCCESS } from './getNotifications/getNotifications.actions';

const initialState = {
  list: []
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_NOTIFICATIONS_SUCCESS: {
      return Object.assign({}, state, { list: payload.notifications });
    }
    default:
      return state;
  }
};
