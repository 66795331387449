import { GET_REQUESTED_FRIENDS_SUCCESS } from './getRequestedFriends/getRequestedFriends.actions';
import { ADD_FRIEND_SUCCESS } from '../friends/addFriend/addFriend.actions';
import { DECLINE_FRIEND_REQUEST_SUCCESS } from '../friendRequests/declineFriendRquest/declineFriendRequest.actions';

const initialState = {
  list: []
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_REQUESTED_FRIENDS_SUCCESS: {
      return Object.assign({}, state, { list: payload.requestedUsers });
    }
    case ADD_FRIEND_SUCCESS: {
      return Object.assign({}, state, {
        list: [payload.addedFriend, ...state.list]
      });
    }
    case DECLINE_FRIEND_REQUEST_SUCCESS: {
      return Object.assign({}, state, {
        list: [...state.list.filter(({ id }) => payload.friendRequestId !== id)]
      });
    }
    default:
      return state;
  }
};
