import { createReducer } from 'utils/reducer';

import {
  READ_CHAT,
  READ_CHAT_FAILED,
  READ_CHAT_SUCCESS
} from './readChat.actions';
import {
  readChat,
  readChatFailed,
  readChatSuccess
} from './readChat.reducer.handler';

const initialState = {
  data: {},
  error: null,
  isSuccessful: false,
  isError: false
};


export default createReducer(initialState, {
  [READ_CHAT]: readChat,
  [READ_CHAT_FAILED]: readChatFailed,
  [READ_CHAT_SUCCESS]: readChatSuccess
});
