import styled, { css } from "styled-components";

const StyledContainer = styled.div`
  text-align: ${({ textAlign }) => textAlign};
  margin-left: auto !important;
  margin-right: auto !important;
  min-width: 992px !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  ${({ fluid }) =>
    fluid &&
    css`
      width: 100%;
      max-width: 100% !important;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    `};
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
    `};
`;

export default StyledContainer;
