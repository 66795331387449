import styled, { css } from 'styled-components';

export default styled.button`
line-height: initial;
  color: ${({ textColor, theme }) =>
    textColor ? textColor : theme.foreground};
  background-color: ${({ color, theme }) =>
    color ? color : theme.colors.natural[700]};

  ${({ size }) => {
    switch (size) {
      case 'big':
        return css`
          font-size: 20px;
          padding: 15px 30px;
          & i {
            font-size: 20px !important;
          }
        `;
      case 'large':
        return css`
          font-size: 16px;
          padding: 12px 16px;
          & i {
            font-size: 16px !important;
          }
        `;
      case 'medium':
        return css`
          font-size: 14px;
          padding: 8px 10px;
          & i {
            font-size: 14px !important;
          }
        `;
      case 'small':
        return css`
          font-size: 12px;
          padding: 8px 20px;
          & i {
            font-size: 12px !important;
          }
        `;
      default:
        return css`
          font-size: 14px;
          padding: 8px 10px;
          & i {
            font-size: 14px !important;
          }
        `;
    }
  }}

  ${({ depiction }) => {
    switch (depiction) {
      case 'default':
        return css`
          border: none;
          border-radius: 0px;
        `;
      case 'bordered':
        return css`
          border: none;
          border-radius: 3px;
        `;
      case 'pill':
        return css`
          border: none;
          border-radius: 9999px;
        `;
      default:
        return css`
          border: none;
          border-radius: 3px;
        `;
    }
  }}

  ${({ hierarchie, color }) => {
    switch (hierarchie) {
      case 'primary':
        return css``;
      case 'secondary':
        return css`
          background-color: transparent;
          border: ${color} 1px solid;
        `;
      case 'tertiary':
        return css`
          background-color: transparent;
          border-color: transparent;
        `;
      default:
        return css``;
    }
  }}

  ${({ fluid }) =>
    fluid &&
    css`
      width: 100%;
    `}
 ${({ disabled }) =>
   disabled &&
   css`
     opacity: 0.5;
     cursor: not-allowed;
   `}

   :focus {outline:0;}
`;
