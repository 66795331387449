import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import Config from 'config';

import API from 'services/api/api';
import setupAxios from 'services/api/axiosBootstrapper';
import sagas from './sagas';
import reducers from './reducers';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
if (Config.Debug) {
  middleware.push(logger);
}
const store = createStore(reducers, applyMiddleware(...middleware));

sagaMiddleware.run(sagas);

setupAxios(API);

export default store;
