import { put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import { LOGIN_REQUEST, loginSuccess, loginFailed } from './login.actions';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import history from 'utils/history';

export function* login({ username, password, isPersistent }) {
  try {
    const token = yield apiPost('/api/account/login', {
      email: username,
      password,
      isPersistent,
    });
    localStorage.setItem('access_token', token);
    // yield put(handleLoadUser(data.token));
    yield put(loginSuccess());
    toast.success(i18n.t('toast:loginSuccess'));
    history.push('/');
  } catch (error) {
    yield put(loginFailed(error));
    toast.error(i18n.t('toast:loginFailed'));
  }
}

export default function* () {
  yield takeLatest(LOGIN_REQUEST, login);
}
