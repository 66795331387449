import styled from 'styled-components';
import Icon from '../../Icon/Icon';
import StyledInput from '../../Input/input.style';

const StyledSearchBarIcon = styled(Icon)`
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  right: 13px;
  top: 17px;
`;

const StyledSearchBarInput = styled(StyledInput)`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  background-color: #7B7B7B;
  outline: none;
  font-size: ${({ theme }) => theme.fontSizes[16]};
  color: white; 
  ::-webkit-input-placeholder {
    font-style: italic;
    color: white;
  }
  :-moz-placeholder {
    font-style: italic; 
    color: white; 
  }
  ::-moz-placeholder {
    font-style: italic;
    color: white;  
  }
  :-ms-input-placeholder {  
    font-style: italic;
    color: white; 
  }
`;

const StyledSearchbar = styled.div`
  position: absolute;
  display: flex;
  left: calc(50% - 152.5px);
  top: 10px;
`;

export {
  StyledSearchBarInput,
  StyledSearchBarIcon,
  StyledSearchbar
};
