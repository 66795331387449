const environment = process.env.NODE_ENV;

const Config = {
  ApiUrl: '',
  ClientUrl: '',
  BlobUrl: '',
};

if (environment === 'development') {
  Config.ApiUrl = 'http://localhost:58865';
  Config.ClientUrl = 'http://localhost:3000';
  Config.BlobUrl = 'https://acety.blob.core.windows.net/acety/';
  Config.Debug = true;
  Config.BlobUrl = 'https://acety.blob.core.windows.net/acety/';
} else if (environment === 'production') {
  Config.ApiUrl = 'https://hank.acety.de';
  Config.ClientUrl = 'https://acety.de';
  Config.Debug = false;
  Config.BlobUrl = 'https://acety.blob.core.windows.net/acety/';
} else {
  alert('NODE_ENV is wrong. It is neither development or production.');
}

export default Config;
