import { put, takeLatest } from 'redux-saga/effects';
import { apiDelete } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  DELETE_USER_POST,
  deleteUserPostsSuccess,
  deleteUserPostsFailed
} from './deleteUserPost.actions';

function* deleteUserPost({ postId }) {
  try {
    yield apiDelete(`/api/user/post/${postId}`);
    yield put(deleteUserPostsSuccess(postId));
  } catch (error) {
    yield put(deleteUserPostsFailed(error));
    toast.error(i18n.t('toast:deletePostFailed'));
  }
}

export default function*() {
  yield takeLatest(DELETE_USER_POST, deleteUserPost);
}
