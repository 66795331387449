import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Register from './register';
import { registerRequest } from 'store/register/register.actions';
import store from 'store/store';

const RegisterContainer = () => {
  const { success, error } = useSelector((state) => state.register);

  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  const handleRegister = () => {
    store.dispatch(registerRequest(username, password, email));
  };

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (success) {
      setPassword('');
      setUsername('');
      setEmail('');
      setMessages([
        'Thank you for registering. Please confirm your email now.',
      ]);
    }
    if (success === false) {
      if (!error.response || !error.response.data) {
        setMessages(['Something went wrong.']);
        return;
      }
      const keys = Object.keys(error.response.data);
      let messages = [];
      keys.forEach((key) => {
        messages.push(error.response.data[key][0]);
      });
      setMessages(messages);
    }
  }, [success, error]);

  return (
    <Register
      {...{
        username,
        password,
        handleRegister,
        setPassword,
        setUsername,
        email,
        setEmail,
        messages,
      }}
    />
  );
};

RegisterContainer.propTypes = {
  location: PropTypes.object.isRequired,
};

export default RegisterContainer;
