export default {
  primary: '#ffa500',
  transparent: '#00000000',
  tmpForeground: '#f2f2f2',
  basic: {
    10: '#0d0d0d',
    20: '#232323',
    30: '#262626',
    40: '#404040',
    50: '#595959',
    60: '#737373',
    70: '#8c8c8c',
    80: '#a6a6a6',
    90: '#bfbfbf',
    100: '#d9d9d9',
    110: '#f2f2f2'
  },
  orange: {
    10: '#1a0c00',
    20: '#4d2400',
    30: '#803c00',
    40: '#b35300',
    50: '#e66b00',
    60: '#FF7700',
    70: '#ff851a',
    80: '#ffa04d',
    90: '#ffbb80',
    100: '#ffd6b3',
    110: '#fff1e5'
  },
  red: {
    10: '#160404',
    20: '#410b0b',
    30: '#6d1313',
    40: '#981a1a',
    50: '#B82020',
    60: '#c32222',
    70: '#dd3c3c',
    80: '#e56767',
    90: '#ec9292',
    100: '#f4bebe',
    110: '#fbe9e9'
  },
  // old one
  natural: {
    100: '#0e0e0e',
    200: '#151515',
    300: '#1c1c1c',
    400: '#232323',
    500: '#383838',
    600: '#4e4e4e',
    650: '#A6A6A6',
    700: '#d3d3d3'
  },
  blue: {
    100: '#203D54',
    200: '#1A4971',
    300: '#2368A2',
    400: '#3183C8',
    500: '#63A2D8',
    600: '#63A2D8',
    700: '#63A2D8'
  },
  green: {
    100: '#145239',
    200: '#197741',
    300: '#259D58',
    400: '#38C172',
    500: '#74D99F',
    600: '#A8EEC1',
    700: '#E3FCEC'
  },
  yellow: {
    100: '#5C4813',
    200: '#8C6D1F',
    300: '#CAA53D',
    400: '#F4CA64',
    500: '#FAE29F',
    600: '#FDF3D7',
    700: '#FDF3D7'
  }
};
