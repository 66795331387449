import React, { Component } from 'react';
import config from 'config';
import './bootstrap.min.css';
import './styles.css';
import './selection.css';
import history from 'utils/history';
import LandingDescription from './startPart/LandingDescription';
import LandingPageScrollDown from './startPart/LandingPageScrollDown';
import LandingWhatComesNext from './whatComesNextPart/LandingWhatComesNext';
import LandingVision from './visionPart/LandingVision';
import LandingContact from './contactPart/LandingContact';
import LandingFooter from './footer/LandingFooter';
import LandingHomeForAllGamers from './startPart/LandingHomeForAllGamers';
import LandingHeader from './startPart/LandingHeader';

export default class LandingPage extends Component {
  componentDidMount() {
    if (localStorage.getItem('accessToken')) {
      history.push('/');
    }
  }

  scrollIt() {
    window.scrollTo({
      behavior: 'smooth',
      top: window.innerHeight,
    });
  }

  render() {
    return (
      <>
        <div className="container-fluid" style={{ padding: 0, margin: 0 }}>
          <div className="view-height">
            <div className="background">
              <div className="background_gradient ">
                <LandingHeader clientUrl={config.ClientUrl} />
                <LandingHomeForAllGamers clientUrl={config.ClientUrl} />
                <LandingDescription clientUrl={config.ClientUrl} />
                <LandingPageScrollDown
                  clientUrl={config.ClientUrl}
                  scrollTo={this.scrollIt}
                />
              </div>
            </div>
          </div>
          <LandingWhatComesNext clientUrl={config.ClientUrl} />
          <LandingVision clientUrl={config.ClientUrl} />
          <LandingContact clientUrl={config.clientUrl} />
          <LandingFooter clientUrl={config.ClientUrl} />
        </div>
      </>
    );
  }
}
