import { SEND_FEEDBACK_SUCCESS } from './sendFeedback/sendFeedback.actions';

const initialState = {};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SEND_FEEDBACK_SUCCESS: {
      return Object.assign({}, state, { isLoaded: true });
    }
    default:
      return state;
  }
};
