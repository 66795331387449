import styled, { css } from 'styled-components';

export default styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  ${({ orientation }) =>
    orientation === 'vertical' &&
    css`
      flex-direction: column;
    `}
  ${({ orientation }) =>
    orientation === 'horizontal' &&
    css`
      flex-direction: row;
    `}
`;
