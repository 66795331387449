import { put, takeLatest } from 'redux-saga/effects';
import { apiGet } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  SEARCH_USER,
  searchUserSuccess,
  searchUserFailed
} from './searchUser.actions';

function* searchUser({ username, searchType }) {
  try {
    const results = yield apiGet(`/api/user/search/${username}/${searchType}`);
    yield put(searchUserSuccess(results));
  } catch (error) {
    yield put(searchUserFailed(error));
    toast.error(i18n.t('toast:searchFailed'));
  }
}

function* searchUserSaga() {
  yield takeLatest(SEARCH_USER, searchUser);
}

export default searchUserSaga;
