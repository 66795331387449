import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Router } from 'react-router-dom';
import CustomRoute from 'components/CustomRoute';
import history from './utils/history';
import routes from './routes';
import store from 'store/store';
import 'react-toastify/dist/ReactToastify.min.css';
import 'services/localization';
import { getTheme } from 'services/theme.service';
import { ThemeProvider } from 'styled-components';

const App = React.lazy(() => import('./views/app'));

const Application = () => (
  <Suspense fallback={<div>Loading....</div>}>
    <App />
  </Suspense>
);

ReactDOM.render(
  <ThemeProvider theme={getTheme}>
    <Router history={history}>
      <Provider store={store}>
        <Switch>
          {routes.map((route) => (
            <CustomRoute key={route.path} {...route} />
          ))}
          <CustomRoute path="/" component={Application} />
        </Switch>
      </Provider>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
