import styled, { css } from 'styled-components';

const StyledMenuItem = styled.div`
  display: flex;
  align-items: center;
  text-align: ${({ textAlign }) => textAlign};
  position: relative;
  padding: ${({ padding }) => padding};
  box-sizing: border-box;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.natural[700] : theme.foreground};
  ${({ disabled, theme }) =>
    !disabled &&
    css`
      &:hover {
        cursor: pointer;
        color: theme.primary;
      }
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      color: theme.primary;
      font-weight: bold;
    `};
  ${({ orientation, spacing }) =>
    orientation === 'horizontal' &&
    css`
      height: 100%;
      padding-left: ${spacing}
      padding-right: ${spacing}
    `}
  ${({ orientation, spacing }) =>
    orientation === 'vertical' &&
    css`
      width: 100%;
      padding-top: ${spacing}
      padding-bottom: ${spacing}
    `};
`;

export default StyledMenuItem;
