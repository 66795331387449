import React from 'react';
import PropTypes from 'prop-types';
import StyledMenu from './menu.style';
import { getParentHeight, getParentWidth } from './helpers/sizes.helper';
import { Container } from 'components';

const Menu = ({
  children,
  orientation,
  backgroundColor,
  color,
  textAlign,
  height,
  width,
  fluid,
  spacing,
  centered,
  ...rest
}) => {
  const parentHeight = getParentHeight(orientation, height, fluid);
  const parentWidth = getParentWidth(orientation, width, fluid);
  return (
    <StyledMenu
      backgroundColor={backgroundColor}
      color={color}
      orientation={orientation}
      height={parentHeight}
      width={parentWidth}
      {...rest}
    >
      {centered ? (
        <Container style={{ display: 'flex' }}>
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              orientation,
              textAlign,
              spacing
            })
          )}
        </Container>
      ) : (
        React.Children.map(children, child =>
          React.cloneElement(child, {
            orientation,
            textAlign,
            spacing
          })
        )
      )}
    </StyledMenu>
  );
};
Menu.defaultProps = {
  orientation: 'horizontal',
  backgroundColor: '#00000000',
  color: '#FFFFFF',
  textAlign: 'center',
  height: '50px',
  width: '150px',
  fluid: true,
  spacing: '10px',
  centered: false
};

Menu.propTypes = {
  children: PropTypes.any,
  orientation: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  textAlign: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  fluid: PropTypes.bool,
  spacing: PropTypes.string,
  centered: PropTypes.bool
};

export default Menu;
