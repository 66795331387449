import React from 'react';
import PropTypes from 'prop-types';

const LandingHomeForAllGamers = ({ clientUrl }) => (
  <div data-aos="fade">
    <div className="row justify-content-center" style={{marginTop: '10px'}}>
      <h1 className="home_for_all_gamers">HOME FOR ALL GAMERS</h1>
    </div>
    <div className="row justify-content-center" style={{ marginTop: '25px' }}>
      <a className="landing_button" href={`${clientUrl}/register`}>
        JOIN US
      </a>
    </div>
  </div>
);

LandingHomeForAllGamers.propTypes = { clientUrl: PropTypes.string.isRequired };

export default LandingHomeForAllGamers;
