import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  LIKE_USER_POST,
  likeUserPostsSuccess,
  likeUserPostsFailed
} from './likeUserPost.actions';

function* likeUserPost({ postId }) {
  try {
    const liker = yield apiPost(`/api/post/like/${postId}`);
    yield put(likeUserPostsSuccess(postId, liker));
  } catch (error) {
    yield put(likeUserPostsFailed(error));
    toast.error(i18n.t('toast:likePostFailed'));
  }
}

export default function*() {
  yield takeLatest(LIKE_USER_POST, likeUserPost);
}
