import LandingPage from 'views/landingPage';
import DataPolicy from 'views/dataPolicy';
import {
  Login,
  Register,
  ConfirmEmail,
} from './services/authentication/components';

export default [
  { path: '/welcome', component: LandingPage },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/confirmEmail', component: ConfirmEmail },
  { path: '/data-policy/ext', component: DataPolicy },
];
