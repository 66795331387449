import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickDetector from 'components/OutsideClickDetector/OutsideClickDetector.component';
import { Icon } from 'components';
import { StyledButton, StyledContent } from './AppendButton.style';
import Option from './AppendButtonOption';
import YouTubeLinkModal from './YouTubeLinkModal';

const AppendButton = ({
  t,
  localId,
  isMediaSet,
  onVideoUpload,
  onImageUpload,
  onDeleteMedia,
  onYouTubeUpload,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  if (!isMediaSet) {
    return (
      <>
        <OutsideClickDetector handleClickOutside={() => setIsExpanded(false)}>
          <div {...rest}>
            <StyledButton color="#2368A2" size="large" onClick={handleClick}>
              <Icon icon="paperclip" />
            </StyledButton>
            {isExpanded && (
              <StyledContent>
                <Option
                  name={`${localId}-addImage`}
                  icon="image"
                  acceptTypes="image/*"
                  onClick={handleUploadImage}
                  input
                />
                <Option
                  name={`${localId}-addVideo`}
                  icon="film"
                  acceptTypes="video/mp4"
                  onClick={handleUploadVideo}
                  input
                />
                <YouTubeLinkModal
                  t={t}
                  trigger={<Option icon="youtube" onClick={handleSelectYoutube} />}
                  handleUploadYoutube={handleUploadYoutube}
                />
              </StyledContent>
            )}
          </div>
        </OutsideClickDetector>
      </>
    );
  }
  return (
    <div {...rest}>
      <StyledButton color="#891B1B" size="large" onClick={handleDeleteMedia}>
        <Icon icon="trash" />
      </StyledButton>
    </div>
  );
  function handleClick() {
    setIsExpanded(!isExpanded);
  }
  function handleUploadImage(e) {
    onImageUpload(e);
    handleClick();
  }
  function handleUploadVideo(e) {
    onVideoUpload(e);
    handleClick();
  }
  function handleUploadYoutube(url) {
    onYouTubeUpload(url);
  }
  function handleDeleteMedia() {
    onDeleteMedia();
  }
  function handleSelectYoutube() {
    handleClick();
  }
};

AppendButton.propTypes = {
  t: PropTypes.func.isRequired,
  localId: PropTypes.string.isRequired,
  isMediaSet: PropTypes.bool.isRequired,
  onVideoUpload: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onYouTubeUpload: PropTypes.func.isRequired,
  onDeleteMedia: PropTypes.func.isRequired
};

export default AppendButton;
