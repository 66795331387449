import React from 'react';
import PropTypes from 'prop-types';
import { StyledModalFooter, StyledActionButton } from './modal.style';

const ModalFooter = ({ align, actions, children, ...rest }) => {
  if (actions.length > 0 || children) {
    return (
      <StyledModalFooter align={align} {...rest}>
        {actions
          ? actions.map(props => (
              <StyledActionButton {...props} align={align} />
            ))
          : children}
      </StyledModalFooter>
    );
  }
  return <div />;
};

ModalFooter.defaultProps = {
  align: 'right',
  children: null,
  actions: []
};

ModalFooter.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  actions: PropTypes.array
};

export default ModalFooter;
