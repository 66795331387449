import { combineReducers } from 'redux';
import createChatReducer from './createChat/createChat.reducer';
import deleteChatReducer from './deleteChat/deleteChat.reducer';
import getChatMessageReducer from './getChatMessage/getChatMessage.reducer';
import getChatMessagesReducer from './getChatMessages/getChatMessages.reducer';
import getChatsReducer from './getChats/getChats.reducer';
import getNewMessageAmountReducer from './getNewMessageAmount/getNewMessageAmount.reducer';
import sendMessageReducer from './sendMessage/sendMessage.reducer';
import readChatReducer from './readChat/readChat.reducer';

import chatReducer from './chat.reducer';

export default combineReducers({
  data: chatReducer,
  getChats: getChatsReducer,
  getChatMessage: getChatMessageReducer,
  getChatMessages: getChatMessagesReducer,
  getNewMessageAmount: getNewMessageAmountReducer,
  createChat: createChatReducer,
  sendMessage: sendMessageReducer,
  readChat: readChatReducer,
  deleteChat: deleteChatReducer
});
