import { createReducer } from 'utils/reducer';

import {
  CREATE_CHAT,
  CREATE_CHAT_FAILED,
  CREATE_CHAT_SUCCESS
} from './createChat.actions';
import {
  createChat,
  createChatFailed,
  createChatSuccess
} from './createChat.reducer.handler';

const initialState = {
  data: {},
  error: null,
  isSuccessful: false,
  isError: false
};

export default createReducer(initialState, {
  [CREATE_CHAT]: createChat,
  [CREATE_CHAT_FAILED]: createChatFailed,
  [CREATE_CHAT_SUCCESS]: createChatSuccess
});
