import styled from 'styled-components';

const StyledChatItem = styled.div`
  width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: ${({ isOwnMessage }) =>
    isOwnMessage ? 'flex-end' : 'flex-start'};
`;

export default StyledChatItem;
