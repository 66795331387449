import React from 'react';
import PropTypes from 'prop-types';
import StyledInput from './input.style';

const Input = ({
  value,
  placeholder,
  onChange,
  type,
  fluid,
  backgroundColor,
  color,
  children,
  ...rest
}) => {
  return (
    <StyledInput
      backgroundColor={backgroundColor}
      color={color}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      type={type}
      fluid={fluid}
      {...rest}
    />
  );
  function handleChange(e) {
    const { value } = e.target;
    onChange && onChange(e, { ...e, value });
  }
};

Input.defaultProps = {
  backgroundColor: '#7b7b7b',
  color: 'white'
};

Input.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  fluid: PropTypes.bool,
  backgroundColor: PropTypes.string,
  color: PropTypes.string
};

export default Input;
