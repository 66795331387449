import styled, { css } from 'styled-components';

const StyledTextArea = styled.textarea`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  resize: vertical;
  padding: 9.5px 14px;
  border-radius: 5px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top-color: rgba(34, 36, 38, 0.15);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgba(34, 36, 38, 0.15);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgba(34, 36, 38, 0.15);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgba(34, 36, 38, 0.15);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  box-sizing: border-box;
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `};
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `};

  ${({ placeholderColor }) =>
    css`
      ::-webkit-input-placeholder {
        color: ${placeholderColor};
      }

      :-moz-placeholder {
        /* Firefox 18- */
        color: ${placeholderColor};
      }

      ::-moz-placeholder {
        /* Firefox 19+ */
        color: ${placeholderColor};
      }

      :-ms-input-placeholder {
        color: ${placeholderColor};
      }

      ::placeholder {
        color: ${placeholderColor};
      }
    `}
`;

export default StyledTextArea;
