import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  SEND_FEEDBACK,
  sendFeedbackSuccess,
  sendFeedbackFailed
} from './sendFeedback.actions';

function* sendFeedback({ text, feedbackType }) {
  try {
    yield apiPost('/api/feedback', {
      text,
      feedbackType
    });
    yield put(sendFeedbackSuccess());
    toast.success(i18n.t('toast:sendFeedback'));
  } catch (error) {
    yield put(sendFeedbackFailed());
    toast.error(i18n.t('toast:sendFeedbackFailed'));
  }
}

export default function*() {
  yield takeLatest(SEND_FEEDBACK, sendFeedback);
}
