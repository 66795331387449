import styled from 'styled-components';

const StyledGrid = styled.div`
    background-color: transparent;
    width: 100%;
    display: block;
`;

const StyledGridColumn = styled.div`
    float: left;
    width: calc(${({width, columnBasis}) => {
        return (width / columnBasis) * 100;
    }}% - ${({columnSpacing}) => {return columnSpacing}}px);
    height: 100%;
    padding: 0px ${({columnSpacing}) => {return columnSpacing/2}}px;
`;

const StyledGridRow = styled.div`
    ::after {
        content: "";
        display: table;
        clear: both;
    }
`;

export {StyledGrid, StyledGridColumn, StyledGridRow};

