export const UNLIKE_USER_POST = 'UNLIKE_USER_POST';
export const UNLIKE_USER_POST_SUCCESS = 'UNLIKE_USER_POST_SUCCESS';
export const UNLIKE_USER_POST_FAILED = 'UNLIKE_USER_POST_FAILED';

export const unlikeUserPost = postId => ({
  type: UNLIKE_USER_POST,
  postId
});

export const unlikeUserPostsSuccess = (postId, postLikeId) => ({
  type: UNLIKE_USER_POST_SUCCESS,
  payload: { postId, postLikeId }
});

export const unlikeUserPostsFailed = error => ({
  type: UNLIKE_USER_POST_FAILED,
  payload: { error }
});
