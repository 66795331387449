import { CONFIRM_EMAIL_REQUEST_SUCCESS } from './confirmEmail.actions';

const initialState = {
  list: [],
};

export default (state = initialState, { type }) => {
  switch (type) {
    case CONFIRM_EMAIL_REQUEST_SUCCESS: {
      return Object.assign({}, state);
    }
    default:
      return state;
  }
};
