import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  SEND_MESSAGE,
  sendMessageSuccess,
  sendMessageFailed
} from './sendMessage.actions';

function* sendMessage({ chatId, text }) {
  try {
    const newMessage = yield apiPost('/api/chat/message/', { chatId, text });
    yield put(sendMessageSuccess(newMessage, chatId));
  } catch (error) {
    yield put(sendMessageFailed(error));
    toast.error(i18n.t('toast:sendMessageFailed'));
  }
}

export default function*() {
  yield takeLatest(SEND_MESSAGE, sendMessage);
}
