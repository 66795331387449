import { updateObject } from 'utils/reducer';

export function sendMessage(state) {
  return updateObject(state, {
    data: {},
    error: null,
    isSuccessful: false,
    isError: false
  });
}

export function sendMessageSuccess(state) {
  return updateObject(state, {
    isSuccessful: true
  });
}

export function sendMessageFailed(state, { payload }) {
  return updateObject(state, {
    error: payload.error,
    isError: true
  });
}
