import React from "react";
import PropTypes from "prop-types";
import StyledContainer from "./container.style";

const Container = ({ children, fluid, textAlign, flex, ...rest }) => (
    <StyledContainer fluid={fluid} textAlign={textAlign} flex={flex} {...rest}>
      {children}
    </StyledContainer>
);

Container.defaultProps = {
  textAlign: "left"
};

Container.propTypes = {
  children: PropTypes.node,
  fluid: PropTypes.bool,
  textAlign: PropTypes.string,
  flex: PropTypes.bool
};

export default Container;
