export const LIKE_USER_POST = 'LIKE_USER_POST';
export const LIKE_USER_POST_SUCCESS = 'LIKE_USER_POST_SUCCESS';
export const LIKE_USER_POST_FAILED = 'LIKE_USER_POST_FAILED';

export const likeUserPost = postId => ({
  type: LIKE_USER_POST,
  postId
});

export const likeUserPostsSuccess = (postId, newPostLike) => ({
  type: LIKE_USER_POST_SUCCESS,
  payload: { postId, newPostLike }
});

export const likeUserPostsFailed = error => ({
  type: LIKE_USER_POST_FAILED,
  payload: { error }
});
