import React from 'react';
import PropTypes from 'prop-types';
import StyledList from './list.style';

const List = ({
  children,
  columns,
  horizontalSpacing,
  verticalSpacing,
  centered,
  ...rest
}) => {
  if (React.Children.count(children) === 0) return <></>;
  const listRows = createListRows(
    children,
    columns,
    horizontalSpacing,
    verticalSpacing
  );
  return (
    <StyledList {...rest}>
      <table
        style={{
          tableLayout: 'fixed',
          width: '100%',
          borderSpacing: '0px',
          textAlign: centered ? 'center' : ''
        }}
      >
        <tbody>{listRows}</tbody>
      </table>
    </StyledList>
  );
};

function createListRows(children, columns, horizontalSpacing, verticalSpacing) {
  const childrenAmount = React.Children.count(children);
  let rowsAmount = Math.ceil(childrenAmount / columns);
  if (childrenAmount === 1)
    return [
      <tr>
        <td style={{ padding: `${verticalSpacing}px ${horizontalSpacing}px` }}>
          {children}
        </td>
      </tr>
    ];

  let rows = [];
  let x = 0;
  let i = 0;
  while (i < rowsAmount) {
    let currentColumns = [];

    let j = 1;
    while (j <= columns) {
      if (children[i]) {
        currentColumns.push(
          <td
            key={`${i}${j}`}
            style={{
              padding: `${verticalSpacing / 2}px ${horizontalSpacing / 2}px`
            }}
          >
            {children[x]}
          </td>
        );
      } else {
        currentColumns.push(<td />);
      }
      j++;
      x++;
    }
    i++;

    rows.push(<tr key={i}>{currentColumns}</tr>);
  }
  return rows;
}

List.defaultProps = {
  columns: 2,
  horizontalSpacing: 0,
  verticalSpacing: 0
};

List.propTypes = {
  columns: PropTypes.number,
  horizontalSpacing: PropTypes.number,
  verticalSpacing: PropTypes.number
};

export default List;
