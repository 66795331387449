import React from 'react';
import PropTypes from 'prop-types';
import { Dock, TextArea, Image, Clearer, Icon } from 'components';
import EditPostImageButton from '../EditPostImageButton';
import {
  StyledButton,
  StyledCancelIcon,
  StyledSaveIcon
} from './EditPost.style';

const EditPost = ({
  t,
  text,
  image,
  isImageSet,
  setText,
  onChangeImage,
  onDeleteImage,
  onSave,
  onCancel
}) => (
  <Dock
    title={t('editPost')}
    style={{ position: 'relative', marginTop: '20px' }}
  >
    <>
      <StyledCancelIcon icon="times" onClick={onCancel} />
      <StyledSaveIcon icon="save" onClick={onSave} />
    </>
    <TextArea
      fluid
      placeholder={t('createPostPlaceholder')}
      rows={2}
      value={text}
      onChange={e => setText(e.target.value)}
    />
    {isImageSet && <Image src={image} fluid style={{ marginTop: '10px' }} />}
    <EditPostImageButton
      isImageSet={isImageSet}
      icon={isImageSet ? 'trash' : 'image'}
      onChangeImage={onChangeImage}
      onDeleteImage={onDeleteImage}
    />
    <StyledButton size="large" color="#2368A2" onClick={onSave}>
      <Icon icon="save" />
    </StyledButton>
    <Clearer />
  </Dock>
);

EditPost.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  isImageSet: PropTypes.bool.isRequired,
  setText: PropTypes.func.isRequired,
  onChangeImage: PropTypes.func.isRequired,
  onDeleteImage: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default EditPost;
