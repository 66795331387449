import React from 'react';
import PropTypes from 'prop-types';
import LandingHeader from '../../../../views/landingPage/startPart/LandingHeader';
import LandingFooter from '../../../../views/landingPage/footer/LandingFooter';
import config from 'config';

const textBoxBaseStyles = {
  border: '3px solid #FF7600',
  boxShadow: '0px 0px 5px #FF7600',
  background: 'rgb(36, 35, 34)',
  color: 'white',
  outline: 'none',
  fontSize: '18px',
  borderRadius: '5px',
  padding: '5px 10px',
  marginTop: '12px',
};

const Register = ({
  handleRegister,
  username,
  setUsername,
  password,
  setPassword,
  email,
  setEmail,
  messages,
}) => (
  <>
    <div className="container-fluid" style={{ padding: 0, margin: 0 }}>
      <div className="view-height">
        <div className="background">
          <div className="background_gradient ">
            <div
              style={{ margin: 'auto', textAlign: 'center', height: '50vh' }}
            >
              <LandingHeader clientUrl={config.ClientUrl} />
              <div>
                <input
                  className="col-12"
                  style={{
                    width: '300px',
                    height: '44px',
                    ...textBoxBaseStyles,
                  }}
                  placeholder={'Email'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <input
                  className="col-12"
                  style={{
                    width: '300px',
                    height: '44px',
                    ...textBoxBaseStyles,
                  }}
                  placeholder={'Username'}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div>
                <input
                  className="col-12"
                  style={{
                    width: '300px',
                    height: '44px',
                    ...textBoxBaseStyles,
                  }}
                  placeholder={'Password'}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div style={{ marginTop: '24px' }}>
                {messages.map((message) => (
                  <p
                    style={{
                      textAlign: 'center',
                      margin: 'auto',
                      color: 'white',
                    }}
                  >
                    {message}
                  </p>
                ))}
              </div>
              <div
                style={{
                  textAlign: 'center',
                  cursor: 'pointer',
                  width: '150px',
                  margin: 'auto',
                  marginTop: '24px',
                }}
                className="landing_button"
                onClick={handleRegister}
                role="button"
              >
                Register
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LandingFooter clientUrl={config.ClientUrl} />
  </>
);

Register.propTypes = {
  handleRegister: PropTypes.func.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  messages: PropTypes.array,
};

export default Register;
