import { put, takeLatest } from 'redux-saga/effects';
import { apiDelete } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import history from 'utils/history';
import {
  DELETE_CHAT,
  deleteChatSuccess,
  deleteChatFailed
} from './deleteChat.actions';

function* deleteChat({ chatId }) {
  try {
    yield apiDelete(`/api/chat/${chatId}`);
    yield put(deleteChatSuccess(chatId));
    history.push('/chat');
  } catch (error) {
    yield put(deleteChatFailed(error));
    toast.error(i18n.t('toast:deleteChatFailed'));
  }
}

export default function* () {
  yield takeLatest(DELETE_CHAT, deleteChat);
}
