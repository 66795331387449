import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input } from 'components';

const YouTubeLinkModal = ({
  t,
  trigger,
  handleUploadYoutube
}) => {
  const [url, setUrl] = useState('');
  const [isValid, setIsValid] = useState(true);
  const modalRef = useRef();
  return (
    <Modal ref={modalRef} trigger={trigger} size="small">
      <Modal.Header>{t('youTubeModal.header')}</Modal.Header>
      <Modal.Body>
        <div style={{ width: '100%' }}>
          <span style={{ color: 'black' }}>
            {t('youTubeModal.description')}
          </span>
          <Input
            fluid
            value={url}
            onChange={(_, { value }) => setUrl(value)}
            style={{
              height: '40px',
              backgroundColor: 'transparent',
              color: 'black'
            }}
          />
          {!isValid && (
            <span style={{ color: 'red' }}>{t('youTubeModal.noValidUrl')}</span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        actions={[
          {
            key: 'cancel',
            textColor: '#414042',
            hierarchie: 'tertiary',
            children: t('youTubeModal.cancel'),
            onClick: () => {
              modalRef.current.hide();
            }
          },
          {
            key: 'submit',
            color: '#4BB462',
            children: t('youTubeModal.submit'),
            onClick: handleSubmit
          }
        ]}
      />
    </Modal>
  );
  function handleSubmit() {
    if (!isValidYoutubeUrl()) setIsValid(false);
    else {
      handleUploadYoutube(url);
      modalRef.current.hide();
    }
  }
  function isValidYoutubeUrl() {
    const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const matches = url.match(p);
    if (matches) return matches[1];
    return false;
  }
};

YouTubeLinkModal.propTypes = {
  t: PropTypes.func.isRequired,
  trigger: PropTypes.node.isRequired,
  handleUploadYoutube: PropTypes.func.isRequired
};

export default YouTubeLinkModal;
