import { put, takeLatest } from 'redux-saga/effects';
import { apiPut } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  UPDATE_USER_PROFILE,
  updateUserProfileSuccess,
  updateUserProfileFailed
} from './updateUserProfile.actions';

function* updateUserProfile({ userData }) {
  try {
    const user = yield apiPut('/api/user/', userData);
    yield put(updateUserProfileSuccess(user));
    toast.success(i18n.t('toast:updateUser'));
  } catch (error) {
    yield put(updateUserProfileFailed(error));
    toast.error(i18n.t('toast:updateUserFailed'));
  }
}

function* updateUserProfileSaga() {
  yield takeLatest(UPDATE_USER_PROFILE, updateUserProfile);
}

export default updateUserProfileSaga;
