import styled from 'styled-components';

const StyledEditImage = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

export const StyledLabel = styled.label`
  background-color: #5199ff;
  border-radius: 3px;
  color: white;
  padding: 8px 10px;
  font-size: 14px;
  cursor: pointer;
`;

export default StyledEditImage;
