import React from 'react';
import PropTypes from 'prop-types';

const LandingWhatComesNextPictureSection = ({ imagePath }) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      backgroundColor: '#242322',
      boxShadow: '0px 0px 5px black'
    }}
  >
    <img
      src={imagePath}
      style={{ height: '100%', maxHeight: '500px', width: '100%' }}
      alt=""
    />
  </div>
);

LandingWhatComesNextPictureSection.propTypes = { imagePath: PropTypes.string.isRequired };

const LandingWhatComesNextTextSection = ({ heading, text }) => (
  <div className="p-md-5 px-0 py-4">
    <div
      style={{
        fontFamily: 'Source Sans Pro',
        color: 'white',
        fontSize: '30px',
        fontWeight: 'bold'
      }}
    >
      {heading}
    </div>
    <div
      style={{
        fontFamily: 'Source Sans Pro',
        color: '#8F8073',
        fontSize: '24px',
        fontWeight: '400'
      }}
    >
      {text}
    </div>
  </div>
);

LandingWhatComesNextTextSection.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

const LandingWhatComesNextRow = ({
  pictureFirst,
  heading,
  text,
  imagePath
}) => (
  <div className="row">
    {!pictureFirst && (
      <div className="col-md-5 col-12">
        <LandingWhatComesNextTextSection heading={heading} text={text} />
      </div>
    )}

    <div className="col-md-7 col-12">
      <LandingWhatComesNextPictureSection imagePath={imagePath} />
    </div>

    {pictureFirst && (
      <div className="col-md-5 col-12">
        <LandingWhatComesNextTextSection heading={heading} text={text} />
      </div>
    )}
  </div>
);

LandingWhatComesNextRow.defaultProps = { pictureFirst: false };

LandingWhatComesNextRow.propTypes = {
  pictureFirst: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired
};

export default LandingWhatComesNextRow;
