import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const LabeledIcon = ({
  text,
  icon,
  iconSize,
  textSize,
  spacing,
  iconColor,
  ...rest
}) => {
  return (
    <div {...rest}>
      <Icon
        icon={icon}
        size={iconSize}
        style={{
          marginRight: spacing,
          verticalAlign: 'middle',
          color: iconColor
        }}
      />
      <span style={{ verticalAlign: 'middle', fontSize: textSize }}>
        {text}
      </span>
    </div>
  );
};

LabeledIcon.defaultProps = {
  textSize: '16px',
  iconSize: '20px',
  spacing: '10px',
  iconColor: 'white'
};

LabeledIcon.propTypes = {
  text: PropTypes.string.isRequired,
  textSize: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string
};

export default LabeledIcon;
