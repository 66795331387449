import React from 'react';
import PropTypes from 'prop-types';
import StyledCenterWrapper from './CenterWrapper.style';

const CenterWrapper = ({ padding, children, ...rest }) => {
  return (
    <StyledCenterWrapper padding={padding} {...rest}>
      {children}
    </StyledCenterWrapper>
  );
};

CenterWrapper.defaultProps = {
  padding: '0x'
};

CenterWrapper.propTypes = {
  padding: PropTypes.string,
  children: PropTypes.object.isRequired
};

export default CenterWrapper;
