import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  CREATE_CHAT,
  createChatSuccess,
  createChatFailed
} from './createChat.actions';

function* createChat({ memberIds, firstMessage }) {
  try {
    const chat = yield apiPost('/api/chat/', { members: memberIds });
    const newMessage = yield apiPost('/api/chat/message/', {
      chatId: chat.id,
      text: firstMessage
    });
    chat.messages = [newMessage];
    chat.newestMessage = newMessage.message;
    chat.newestMessageDate = Date(Date.now);

    yield put(createChatSuccess(chat));
  } catch (error) {
    yield put(createChatFailed(error));
    toast.error(i18n.t('toast:sendMessageFailed'));
  }
}

export default function* () {
  yield takeLatest(CREATE_CHAT, createChat);
}
