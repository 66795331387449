import {
  GET_OWN_USER_FAILED,
  GET_OWN_USER_SUCCESS,
} from './getOwnUser/getOwnUser.actions';
import { UPDATE_USER_PROFILE_SUCCESS } from '../user/updateUserProfile/updateUserProfile.actions';
import config from 'config';

const initialState = {};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_OWN_USER_SUCCESS: {
      return Object.assign({}, state, payload.ownUser);
    }
    case UPDATE_USER_PROFILE_SUCCESS: {
      return Object.assign({}, state, payload.user);
    }
    case GET_OWN_USER_FAILED: {
      window.location.href = `${config.ClientUrl}/welcome`;
      return state;
    }
    default:
      return state;
  }
};
