import styled from 'styled-components';
import YouTubePlayer from 'react-player/lib/players/YouTube';

export const StyledMediaImage = styled.img`
  width: 100%;
`;

export const StyledMediaLoading = styled.div`
  width: 100%;
  height: 225px;
  background-color: ${({ theme }) => theme.colors.natural[200]};
`;

export const StyledMediaError = styled.div`
  width: 100%;
  height: 225px;
  background-color: ${({ theme }) => theme.background};
  text-align: center;
  vertical-align: middle;
  line-height: 225px;
`;

export const StyledYoutubeWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

export const StyledYoutubePlayer = styled(YouTubePlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;
