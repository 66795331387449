export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILED = 'SEARCH_USER_FAILED';

export const searchUser = (username, searchType) => ({
  type: SEARCH_USER,
  username,
  searchType
});
searchUser.user = 0;

export const searchUserSuccess = results => ({
  type: SEARCH_USER_SUCCESS,
  payload: { results }
});

export const searchUserFailed = error => ({
  type: SEARCH_USER_FAILED,
  payload: { error }
});
