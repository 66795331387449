export const READ_CHAT = 'READ_CHAT';
export const READ_CHAT_SUCCESS = 'READ_CHAT_SUCCESS';
export const READ_CHAT_FAILED = 'READ_CHAT_FAILED';

export const readChat = chatId => ({
  type: READ_CHAT,
  chatId
});

export const readChatSuccess = chatId => ({
  type: READ_CHAT_SUCCESS,
  payload: { chatId }
});

export const readChatFailed = error => ({
  type: READ_CHAT_FAILED,
  payload: { error }
});
