import React from "react";
import PropTypes from "prop-types";
import StyledHeaderBar from "./headerBar.style";

const HeaderBar = ({ text, image, ...rest }) => (
  <StyledHeaderBar {...rest}>
    {image && <img src={image} alt={image} />}
    <div style={image && { marginLeft: "40px" }}>{text}</div>
  </StyledHeaderBar>
);

HeaderBar.propTypes = {
  text: PropTypes.string.isRequired
};

export default HeaderBar;
