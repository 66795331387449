import { updateArray, updateItemInArray, updateObject } from 'utils/reducer';
import generateId from 'services/idGenerator.service';
import history from 'utils/history';

function orderMessagesByTime(messages) {
  return messages.sort(function(x, y) {
    if (y.createdAt > x.createdAt) return -1;
    else if (y.createdAt < x.createdAt) return 1;
    else return 0;
  });
}

function orderChatsByTime(chats) {
  return chats.sort(function(x, y) {
    if (y.newestMessageDate > x.newestMessageDate) return 1;
    else if (y.newestMessageDate < x.newestMessageDate) return -1;
    else return 0;
  });
}

function updateState(state, newState) {
  return updateArray(state, orderChatsByTime(newState));
}

export function getChats(state, { payload }) {
  return updateState(state, payload.chats);
}

export function getChatMessages(state, { payload }) {
  const newChatList = updateItemInArray(state, payload.chatId, chat =>
    updateObject(chat, { messages: orderMessagesByTime(payload.messages) })
  );
  return updateState(state, newChatList);
}

export function readChat(state, { payload }) {
  return updateItemInArray(state, payload.chatId, chat =>
    updateObject(chat, { newMessagesCount: 0 })
  );
}

export function getChatMessage(state, { payload }) {
  const { message } = payload;
  const { pathname } = history.location;
  const urlParts = pathname.split('/');
  const isOpenChat = urlParts[2] === message.username && urlParts[1] === 'chat';
  const newMessage = {
    ...message,
    createdAt: Date(Date.now),
    id: generateId(),
    userName: message.username
  };
  const newChatList = updateItemInArray(state, message.chatId, chat =>
    updateObject(chat, {
      newestMessage: message.message,
      newestMessageDate: Date(Date.now),
      messages: chat.messages ? orderMessagesByTime([...chat.messages, newMessage]) : [newMessage],
      newMessagesCount: isOpenChat ? 0 : chat.newMessagesCount + 1
    })
  );
  return updateState(state, newChatList);
}

export function sendMessage(state, { payload }) {
  const newChatList = updateItemInArray(state, payload.chatId, chat =>
    updateObject(chat, {
      newestMessageDate: Date(Date.now),
      newestMessage: payload.newMessage.message,
      messages: [...chat.messages, payload.newMessage]
    })
  );
  return updateState(state, newChatList);
}

export function createChat(state, { payload }) {
  return updateArray(state, [payload.chat, ...state]);
}

export function deleteChat(state, { payload }) {
  const newChatList = state.filter(x => x.id !== payload.chatId);
  return updateState([], newChatList);
}
