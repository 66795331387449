import styled from 'styled-components';

export const StyledDropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const StyledDropdownContent = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.natural[600]};
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
`;

export const StyledDropdownItem = styled.a`
  color: ${({ theme }) => theme.foreground};
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.natural[500]};
  }
`;
