import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import { StyledItem } from './AppendButton.style';

const AppendButtonOption = ({ name, icon, acceptTypes, onClick, input }) => (
  <>
    <StyledItem htmlFor={name} onClick={input ? {} : onClick}>
      <Icon icon={icon} size="20px" />
    </StyledItem>
    {input && (
      <input
        id={name}
        type="file"
        multiple="multiple"
        name="_photos"
        accept={acceptTypes}
        style={{ visibility: 'hidden', display: 'none' }}
        onChange={onClick}
      />
    )}
  </>
);

AppendButtonOption.defaultProps = {
  name: '',
  input: false,
  acceptTypes: ''
};

AppendButtonOption.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string.isRequired,
  acceptTypes: PropTypes.string,
  input: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default AppendButtonOption;
