import styled, { css } from 'styled-components';

const StyledTooltip = styled.div`
  position: relative;
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: ${({ theme }) => theme.foreground};
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
  }

  .tooltiptext::after {
    content: '';
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
  &:hover {
    cursor: pointer;
  }

  ${({ position }) =>
    position === 'top'
      ? css`
          .tooltiptext {
            bottom: 120%;
            left: 50%;
            margin-left: -60px;
          }
          .tooltiptext::after {
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-color: black transparent transparent transparent;
          }
        `
      : position === 'bottom'
      ? css`
          .tooltiptext {
            top: 120%;
            left: 50%;
            margin-left: -60px;
          }
          .tooltiptext::after {
            bottom: 100%;
            left: 50%;
            border-color: transparent transparent black transparent;
          }
        `
      : position === 'left'
      ? css`
          .tooltiptext {
            top: -5px;
            right: 110%;
          }
          .tooltiptext::after {
            top: 50%;
            left: 100%;
            margin-top: -5px;
            border-color: transparent transparent transparent black;
          }
        `
      : position === 'right' &&
        css`
          .tooltiptext {
            top: -5px;
            left: 110%;
          }
          .tooltiptext::after {
            top: 50%;
            right: 100%;
            margin-top: -5px;
            border-color: transparent black transparent transparent;
          }
        `}
`;

export default StyledTooltip;
