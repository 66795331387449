import i18n from 'i18next';
import langDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Config from 'config';

import translationEN from './languages/en.json';
import translationDE from './languages/de.json';

const detectionOptions = {
  order: ['localStorage'],
};

i18n
  .use(langDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: translationEN,
      de: translationDE,
    },
    fallbackLng: 'en',
    detection: detectionOptions,
    interpolation: {
      escapeValue: false,
    },
    debug: Config.Debug,
    react: {
      wait: true,
    },
  });

export default i18n;
