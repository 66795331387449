import React from "react";
import PropTypes from "prop-types";
import StyledDateLabel from "./dateLabel.style";
import moment from "moment";

const DateLabel = ({ date, fontSize, color, ...rest }) => {
  var d1 = new Date(date);
  var d2 = new Date();
  const diffTime = Math.abs(d2.getTime() - d1.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return (
    <StyledDateLabel fontSize={fontSize} color={color} {...rest}>
      {diffDays < 7
        ? moment(date)
            .fromNow()
            .toString()
        : moment(date)
            .format("MMMM Do YYYY")
            .toString()}
    </StyledDateLabel>
  );
};

DateLabel.defaultProps = {
  fontSize: "12px",
  color: "white"
};

DateLabel.propTypes = {
  date: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default DateLabel;
