import React from 'react';
import PropTypes from 'prop-types';
import { Dock, TextArea, Clearer, Icon, Media } from 'components';
import AppendButton from '../AppendButton';
import StyledButton from './CreatePostForm.style';

const CreatePostForm = ({
  t,
  text,
  preview,
  mediaType,
  onSetText,
  onVideoUpload,
  onImageUpload,
  onYouTubeUpload,
  onDeleteMedia,
  onSendPost
}) => (
  <Dock title={t('createPost')} style={{ position: 'relative', marginBottom: '20px' }}>
    <TextArea
      fluid
      placeholder={t('createPostPlaceholder')}
      rows={2}
      value={text}
      onChange={onSetText}
      backgroundColor="#00000000"
      color="white"
      style={{ border: '1px solid #7b7b7b' }}
    />
    {mediaType && <Media src={preview} type={mediaType} />}
    <AppendButton
      t={t}
      isMediaSet={preview !== null}
      style={{ position: 'absolute', right: '90px', bottom: '20px' }}
      onVideoUpload={onVideoUpload}
      onImageUpload={onImageUpload}
      onDeleteMedia={onDeleteMedia}
      onYouTubeUpload={onYouTubeUpload}
    />
    <StyledButton color="#2368A2" size="large" onClick={onSendPost}>
      <Icon icon="send" />
    </StyledButton>
    <Clearer />
  </Dock>
);

CreatePostForm.propTypes = {
  text: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onSetText: PropTypes.func.isRequired,
  onVideoUpload: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onYouTubeUpload: PropTypes.func.isRequired,
  onDeleteMedia: PropTypes.func.isRequired,
  onSendPost: PropTypes.func.isRequired
};

export default CreatePostForm;
