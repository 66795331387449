import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  READ_NOTIFICATION,
  readNotificationSuccess,
  readNotificationFailed
} from './readNotification.actions';

function* readNotification({ notificationId }) {
  try {
    const notification = yield apiPost(`/api/notifications/${notificationId}`);
    yield put(readNotificationSuccess(notification));
  } catch (error) {
    yield put(readNotificationFailed(error));
    toast.error(i18n.t('toast:readNotificationFailed'));
  }
}

export default function*() {
  yield takeLatest(READ_NOTIFICATION, readNotification);
}
