import React from 'react';
import PropTypes from 'prop-types';
import StyledChatBubble from './chatBubble.style';

const ChatBubble = ({ message, isOwnMessage, sendState, ...rest }) => (
  <StyledChatBubble isOwnMessage={isOwnMessage} sendState={sendState} {...rest}>
    {message}
  </StyledChatBubble>
);

ChatBubble.defaultProps = {
  sendState: 'send'
};

ChatBubble.propTypes = {
  message: PropTypes.string.isRequired,
  isOwnMessage: PropTypes.bool.isRequired,
  sendState: PropTypes.oneOf(['send', 'sending', 'failed'])
};

export default ChatBubble;
