import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  ADD_FRIEND,
  addFriendSuccess,
  addFriendFailed
} from './addFriend.actions';

function* addFriend({ userId }) {
  try {
    const friend = yield apiPost(`/api/friends/request/${userId}`);
    yield put(addFriendSuccess(friend));
    toast.success(i18n.t('toast:addFriend'));
  } catch (error) {
    yield put(addFriendFailed(error));
    toast.error(i18n.t('toast:addFriendFailed'));
  }
}

function* addFriendSaga() {
  yield takeLatest(ADD_FRIEND, addFriend);
}

export default addFriendSaga;
