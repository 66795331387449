import { put, takeLatest } from 'redux-saga/effects';
import { apiGet } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  GET_USER_POSTS,
  getUserPostsSuccess,
  getUserPostsFailed
} from './getUserPosts.actions';

function* getUserPosts({ userId }) {
  try {
    const posts = yield apiGet(`/api/user/${userId}/posts`);
    yield put(getUserPostsSuccess(posts));
  } catch (error) {
    yield put(getUserPostsFailed(error));
    toast.error(i18n.t('toast:getPostFailed'));
  }
}

export default function*() {
  yield takeLatest(GET_USER_POSTS, getUserPosts);
}
