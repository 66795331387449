import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CreatePostForm from './CreatePostForm';

const CreatePostFormContainer = ({ onSendUserPost, ...rest }) => {
  const [text, setText] = useState('');
  const [media, setMedia] = useState({
    file: null,
    preview: null,
    type: null
  });
  const { t } = useTranslation('post');
  return (
    <CreatePostForm
      t={t}
      text={text}
      onSetText={handleSetText}
      preview={media.preview}
      mediaType={media.type}
      onVideoUpload={handleVideoUpload}
      onImageUpload={handleImageUpload}
      onYouTubeUpload={handleYouTubeUpload}
      onDeleteMedia={handleDeleteMedia}
      onSendPost={handleSendUserPost}
      {...rest}
    />
  );
  function handleSetText(e) {
    setText(e.target.value);
  }
  function handleSendUserPost() {
    if (!text) return;
    if (media.type !== 'youtube') onSendUserPost(text, media.file, null);
    else onSendUserPost(text, null, media.file);
  }
  function handleDeleteMedia() {
    setMedia({ file: null, preview: null, type: null });
  }
  function handleVideoUpload(video) {
    handleFileUpload(video, 'video');
  }
  function handleImageUpload(image) {
    handleFileUpload(image, 'image');
  }
  function handleYouTubeUpload(url) {
    setMedia({ preview: url, file: url, type: 'youtube' });
  }
  function handleFileUpload(mediaFile, type) {
    const reader = new FileReader();
    const file = mediaFile.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setMedia({ file, preview: reader.result, type });
      };
      reader.readAsDataURL(file);
    } else {
      setMedia({ file: '', preview: '', type });
    }
  }
};

CreatePostFormContainer.propTypes = {
  onSendUserPost: PropTypes.func.isRequired
};

export default CreatePostFormContainer;
