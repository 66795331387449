import React from 'react';
import PropTypes from 'prop-types';
import LandingPageSectionHeader from '../shared/LandingPageSectionHeader';

const LandingVision = ({ clientUrl }) => (
  <div id="vision-part" className="p-5" style={{ backgroundColor: '#242322' }}>
    <LandingPageSectionHeader content="Our Vision" />
    <div
      className="text-md-center text-left"
      style={{
        fontFamily: 'Source Sans Pro',
        color: '#8F8073',
        fontSize: '24px',
        fontWeight: '400',
        marginTop: '70px'
      }}
    >
      <p>
        Our vision is to build the number one gamingplatform called Acety
        together with YOU. We want that every gamer uses this platform at a
        daily basis. Everything we develop is based on your ideas and wishes. To
        get started we developed basic features like Posts, Chat, User Profiles,
        Friends and Notifications. What comes next is decided by YOU.
      </p>
      <p>
        Features which are possible in the next releases are Clans where you can
        meet your teammates. Other possible features are a transfermarket where
        you can search or headhunt other players, Statistics where you can see
        how your gaming career is going on, a Newsfeed where you can see posts
        of your friends. But that are only our ideas. For sure you have better
        ideas.
      </p>
      <p>
        In the future we want to have a rich feature gamingplatform which is/was
        driven by your ideas. All gamers together and connected in one place.
        Let us fulfill this dream. Just contact us over the provided form. We
        will answer you as fast as possible.
      </p>
    </div>
  </div>
);

LandingVision.propTypes = { clientUrl: PropTypes.string.isRequired };

export default LandingVision;
