export const GET_FRIEND_REQUESTS = 'GET_FRIEND_REQUESTS';
export const GET_FRIEND_REQUESTS_SUCCESS = 'GET_FRIEND_REQUESTS_SUCCESS';
export const GET_FRIEND_REQUESTS_FAILED = 'GET_FRIEND_REQUESTS_FAILED';

export const getFriendRequests = () => ({
  type: GET_FRIEND_REQUESTS
});
export const getFriendRequestsSuccess = friendRequests => ({
  type: GET_FRIEND_REQUESTS_SUCCESS,
  payload: { friendRequests }
});
export const getFriendRequestsFailed = error => ({
  type: GET_FRIEND_REQUESTS_FAILED,
  payload: { error }
});
