import React from 'react';
import PropTypes from 'prop-types';
import StyledTextArea from './textArea.style';

const TextArea = ({
  onChange,
  rows,
  value,
  minHeight,
  maxHeight,
  backgroundColor,
  color,
  placeholder,
  ...rest
}) => {
  return (
    <StyledTextArea
      backgroundColor={backgroundColor}
      color={color}
      onChange={handleChange}
      rows={rows}
      value={value}
      minHeight={minHeight}
      maxHeight={maxHeight}
      placeholder={placeholder}
      {...rest}
    />
  );
  function handleChange(e) {
    const value = e.target.value;
    onChange && onChange(e, { ...e, value });
  }
};

TextArea.defaultProps = {
  rows: 3,
  backgroundColor: '#7b7b7b',
  color: 'white',
  placeholderColor: '#a9a9a9'
};

TextArea.propTypes = {
  onChange: PropTypes.func,
  rows: PropTypes.number.isRequired,
  value: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string
};

export default TextArea;
