import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const MediaVideo = ({ src, ...rest }) => (
  <ReactPlayer
    url={src}
    width="100%"
    height="auto"
    controls
    {...rest}
  />
);

MediaVideo.propTypes = { src: PropTypes.string.isRequired };

export default MediaVideo;
