export const DELETE_CHAT = 'DELETE_CHAT';
export const DELETE_CHAT_SUCCESS = 'DELETE_CHAT_SUCCESS';
export const DELETE_CHAT_FAILED = 'DELETE_CHAT_FAILED';

export const deleteChat = chatId => ({
  type: DELETE_CHAT,
  chatId
});

export const deleteChatSuccess = chatId => ({
  type: DELETE_CHAT_SUCCESS,
  payload: { chatId }
});

export const deleteChatFailed = error => ({
  type: DELETE_CHAT_FAILED,
  payload: { error }
});
