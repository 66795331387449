import {
  REGISTER_REQUEST_SUCCESS,
  REGISTER_REQUEST_FAILED,
} from './register.actions';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case REGISTER_REQUEST_SUCCESS: {
      return Object.assign({}, state, payload);
    }
    case REGISTER_REQUEST_FAILED: {
      return Object.assign({}, state, payload);
    }
    default:
      return state;
  }
};
