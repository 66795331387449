import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickDetector from 'components/OutsideClickDetector/OutsideClickDetector.component';
import {
  StyledDropdown,
  StyledDropdownContent,
  StyledDropdownItem
} from './dropdown.style';

const Dropdown = ({ children, options, itemSelected, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <OutsideClickDetector handleClickOutside={() => setIsOpen(false)}>
      <StyledDropdown>
        <div onClick={() => setIsOpen(!isOpen)}>{children}</div>
        <StyledDropdownContent {...rest}>
          {isOpen &&
            options &&
            options.map(option => (
              <StyledDropdownItem
                key={option.key}
                onClick={() => {
                  itemSelected(option);
                  setIsOpen(false);
                }}
                value={option.text}
              >
                {option.text}
              </StyledDropdownItem>
            ))}
        </StyledDropdownContent>
      </StyledDropdown>
    </OutsideClickDetector>
  );
};

Dropdown.propTypes = {
  children: PropTypes.any,
  options: PropTypes.array,
  itemSelected: PropTypes.func
};

export default Dropdown;
