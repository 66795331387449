import { LOGIN_REQUEST_SUCCESS } from './login.actions';

const initialState = {
  list: [],
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case LOGIN_REQUEST_SUCCESS: {
      return Object.assign({}, state, { token: payload });
    }
    default:
      return state;
  }
};
