export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED';

export const deleteNotification = notificationId => ({
  type: DELETE_NOTIFICATION,
  notificationId
});

export const deleteNotificationSuccess = notification => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: { notification }
});

export const deleteNotificationFailed = error => ({
  type: DELETE_NOTIFICATION_FAILED,
  payload: { error }
});
