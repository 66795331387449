import styled, { css } from 'styled-components';

export const baseStyle = css`
  margin-bottom: ${({ noMargin }) => noMargin && '0'};
  color: ${({ color }) => color};
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-top: 10px;
  text-align: ${props => {
    if (props.center) return 'center';
    if (props.right) return 'right';
    return 'left';
  }};

  max-width: 100%;
`;

export const HeaderOne = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[48]};
  font-weight: bold;
  margin-bottom: 25px;
  ${baseStyle};
  @media (max-width: 480px) {
    font-size: ${({ theme }) => theme.fontSizes[48]};
  }
`;

export const HeaderTwo = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes[36]};
  font-weight: bold;
  margin-bottom: 20px;
  ${baseStyle};
`;

export const HeaderThree = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes[24]};
  font-weight: bold;
  margin-bottom: 15px;
  ${baseStyle};
`;

export const HeaderFour = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes[20]};
  font-weight: bold;
  margin-bottom: 10px;
  ${baseStyle};
`;

export const HeaderFive = styled.h5`
  font-size: ${({ theme }) => theme.fontSizes[18]};
  font-weight: bold;
  margin-bottom: 5px;
  ${baseStyle};
`;
