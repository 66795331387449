export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILED = 'LOGIN_REQUEST_FAILED';

export const loginRequest = (username, password, isPersistent) => ({
  type: LOGIN_REQUEST,
  username,
  password,
  isPersistent,
});

export const loginSuccess = () => ({
  type: LOGIN_REQUEST_SUCCESS,
});

export const loginFailed = (error) => ({
  type: LOGIN_REQUEST_FAILED,
  payload: { error },
});
