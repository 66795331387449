import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Config from 'config';

import MediaImage from './MediaImage';
import MediaVideo from './MediaVideo';
import MediaLoading from './MediaLoading';
import MediaError from './MediaError';
import MediaYoutube from './MediaYoutube';

const MediaContainer = ({ src, type }) => {
  const [mediaType, setMediaType] = useState(type);
  if (mediaType === 'loading') loadMedia();
  switch (mediaType) {
    case 'loading':
      return <MediaLoading />;
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image':
      return <MediaImage src={src} />;
    case 'video/mp4':
    case 'video':
      return <MediaVideo src={src} />;
    case 'youtube':
      return <MediaYoutube src={src} />;
    case 'error':
    default:
      return <MediaError />;
  }
  function loadMedia() {
    if (src.includes(Config.BlobUrl)) {
      axios
        .get(src, { responseType: 'blob', timeout: 30000 })
        .then(res => {
          setMediaType(res.headers['content-type'].toLocaleLowerCase());
        })
        .catch(() => {
          setMediaType('error');
        });
    } else if (src.includes('youtu')) setMediaType('youtube');
    else setMediaType('error');
  }
};

MediaContainer.defaultProps = { type: 'loading' };

MediaContainer.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default MediaContainer;
