import styled from 'styled-components';
import Icon from '../../../Icon';
import { Button } from 'components';

export const StyledButton = styled(Button)`
  float: right;
  margin-top: 10px;
  margin-left: 10px;
`;

export const StyledCancelIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 20px !important;
  cursor: pointer;

  &:hover {
    color: orange;
  }
`;

export const StyledSaveIcon = styled(Icon)`
  position: absolute;
  top: 13px;
  right: 45px;
  font-size: 18px !important;
  cursor: pointer;

  &:hover {
    color: orange;
  }
`;
