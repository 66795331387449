import { updateObject } from 'utils/reducer';

export function searchGlobal(state) {
  return updateObject(state, {
    error: null,
    isSuccessful: false,
    isError: false
  });
}

export function searchGlobalSuccess(state, { payload }) {
  return updateObject(state, {
    data: payload.searchResults,
    isSuccessful: true
  });
}

export function searchGlobalFailed(state, { payload }) {
  return updateObject(state, {
    error: payload.error,
    isError: true
  });
}
