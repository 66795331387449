import { put, takeLatest } from 'redux-saga/effects';
import { apiPost } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  ACCEPT_FRIEND_REQUEST,
  acceptFriendRequestSuccess,
  acceptFriendRequestFailed
} from './acceptFriendRequest.actions';

function* acceptFriendRequest({ friendRequestId }) {
  try {
    const newFriend = yield apiPost(`/api/friends/${friendRequestId}`);
    yield put(acceptFriendRequestSuccess(friendRequestId, newFriend));
  } catch (error) {
    yield put(acceptFriendRequestFailed(error));
    toast.error(i18n.t('toast:acceptFriendRequestFailed'));
  }
}

function* acceptFriendRequestSaga() {
  yield takeLatest(ACCEPT_FRIEND_REQUEST, acceptFriendRequest);
}

export default acceptFriendRequestSaga;
