import styled from 'styled-components';
import Icon from '../../../Icon/Icon';

export const StyledLabel = styled.label`
  background-color: ${({ isImageSet }) => (isImageSet ? '#891B1B' : '#2368A2')};
  height: 50px;
  width: 50px;
  position: absolute;
  right: 90px;
  bottom: 12px;
  border-radius: 5px;
  color: white;
  padding: 6px 16px;
  font-size: 16px;
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
