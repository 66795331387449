import { put, takeLatest } from 'redux-saga/effects';
import { apiGet } from 'services/api/restService.service';
import { toast } from 'react-toastify';
import i18n from 'services/localization';
import {
  GET_CHAT_MESSAGES,
  getChatMessagesSuccess,
  getChatMessagesFailed
} from './getChatMessages.actions';

function* getChatMessages({ chatId }) {
  try {
    const messages = yield apiGet(`/api/chat/${chatId}`);
    yield put(getChatMessagesSuccess(messages, chatId));
  } catch (error) {
    yield put(getChatMessagesFailed(error));
    toast.error(i18n.t('toast:getMessagesFailed'));
  }
}

export default function*() {
  yield takeLatest(GET_CHAT_MESSAGES, getChatMessages);
}
