import styled from 'styled-components';

const StylesImage = styled.img`
  height: ${({ size, fluid }) => (fluid ? 'auto' : size)};
  width: ${({ size, fluid }) => (fluid ? '100%' : size)};
  border-radius: ${({ rounded }) => (rounded ? '50%' : '3px')};
  display: block;
`;

export default StylesImage;
