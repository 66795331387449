import React from 'react';
import PropTypes from 'prop-types';

const textBoxBaseStyles = {
  border: '3px solid #FF7600',
  boxShadow: '0px 0px 5px #FF7600',
  background: 'transparent',
  color: 'white',
  outline: 'none',
  fontSize: '18px',
  borderRadius: '5px',
  padding: '5px 10px'
};

const LandingContactTextBox = ({ setValue, label, value, isTextarea }) => (
  <div style={{ margin: 'auto', textAlign: 'center' }}>
    <div
      style={{
        color: 'white',
        fontFamily: 'Source Sans Pro',
        marginBottom: '10px',
        fontWeight: 'bold',
        fontSize: '18px'
      }}
    >
      {label}
    </div>
    {!isTextarea && (
      <input
        className="col-md-3 col-6"
        style={{
          width: '300px',
          height: '44px',
          ...textBoxBaseStyles
        }}
        onChange={e => setValue(e.target.value)}
        value={value}
      />
    )}
    {isTextarea && (
      <textarea
        className="col-md-6 col-12"
        style={{
          height: '125px',
          minHeight: '125px',
          ...textBoxBaseStyles
        }}
        onChange={e => setValue(e.target.value)}
        value={value}
      />
    )}
  </div>
);

LandingContactTextBox.defaultProps = { isTextarea: false };

LandingContactTextBox.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isTextarea: PropTypes.bool
};

export default LandingContactTextBox;
